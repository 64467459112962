export interface IMiniAppData {
  callBackUrl: string;
  mode?: MiniAppMode;
  email?: string;
  walletAddress?: string;
}

export enum MiniAppMode {
  register = 'register',
  mint = 'mint'
}
