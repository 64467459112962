<form
  *ngIf="userDetail"
  class="d-flex flex-column align-items-baseline h-100"
  (submit)="checkPassword()"
>
  <span class="font-30 text-EAE9E3 font-all-round-gothic-medium d-block">
    Your session has expired!
  </span>

  <span class="font-14 text-EAE9E3 font-all-round-gothic-medium d-block mt-2">
    Please enter the password for your account associated with the email
    {{ userDetail.email }} to continue
  </span>

  <nz-form-item class="w-100 mb-0 mt-50px">
    <nz-form-control [nzSpan]="24" nzErrorTip="Incorrect Password!">
      <nz-form-label
        [nzSpan]="24"
        nzFor="email"
        class="p-0 font-14 text-EAE9E3 font-all-round-gothic-medium"
      >
        Password
      </nz-form-label>

      <nz-input-group
        [nzSuffix]="passwordSuffix"
        class="w-100 bg-transparent border-0 shadow-none no-outline mt-3 p-0"
      >
        <input
          #inputPassword
          [formControl]="password"
          [type]="passwordVisible ? 'text' : 'password'"
          nz-input
          class="w-100 bg-transparent border-0 shadow-none no-outline text-left text-EBF7FA font-19 font-mundial-light"
          placeholder="Enter your password"
          id="password"
          name="password"
          autofocus
        />
      </nz-input-group>
    </nz-form-control>
  </nz-form-item>
</form>

<div *nzModalFooter class="d-flex flex-column mt-50px w-100 border-0">
  <button
    nz-button
    nzType="primary"
    nzShape="round"
    class="d-flex align-items-center justify-content-center border-0 no-shadow w-100 h-54px"
    nzSize="large"
    (click)="checkPassword()"
    [nzLoading]="loading"
  >
    <span *ngIf="!loading" class="font-14 font-all-round-gothic-medium">
      Continue
    </span>
  </button>
</div>

<ng-template #passwordSuffix>
  <span class="pointer" (click)="passwordVisible = !passwordVisible">
    <ng-container *ngIf="passwordVisible; then eyeOff; else eye"></ng-container>
  </span>
</ng-template>

<ng-template #eye>
  <span
    nz-icon
    nzType="amates:eye"
    class="text-EBF7FA font-20 fill-transparent"
  ></span>
</ng-template>

<ng-template #eyeOff>
  <span nz-icon nzType="amates:eyeOff" class="text-EBF7FA font-20"></span>
</ng-template>
