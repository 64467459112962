import { Injectable } from '@angular/core';
import { NDDClientInfoServiceImpl } from './client-info';
import { environment } from 'src/environments/environment';
import { Clipboard } from '@angular/cdk/clipboard';
import { NzNotificationService } from 'ng-zorro-antd/notification';

@Injectable()
export class UtilsService {
  public baseLogoIconsUrl = environment.BaseLogoIconsUrl;

  constructor(
    protected clientInfoServiceImpl: NDDClientInfoServiceImpl,
    private clipboard: Clipboard,
    private notificationService: NzNotificationService
  ) {}

  public isEmptyOrSpaces(str: string) {
    return str == undefined || str === null || str.match(/^ *$/) !== null;
  }

  objectKeys(obj: {}): any[] {
    return Object.keys(obj);
  }

  updateUrl(el: any) {
    el.target.src = this.baseLogoIconsUrl + '00-none.png';
  }

  public copyText(text?: string) {
    if (!text) {
      return;
    }

    this.clipboard.copy(text);
    this.notificationService.success('Success', 'has been copied to clipboard');
  }

  openLink(link?: string) {
    if (!link) {
      this.notificationService.error('Error', 'Invalid link');
      return;
    }

    window.open(link, '_blank');
  }

  public encodeQueryData(data: any): string {
    const ret: any[] = [];
    for (let d in data)
      ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
    return (data ? '?' : null) + ret.join('&');
  }
}
