import { Pipe, PipeTransform } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { CrowdWalletService } from '../services';

@Pipe({
  name: 'ScanTransactionUrl'
})
export class ScanTransactionUrlPipe implements PipeTransform {
  constructor(
    private notificationService: NzNotificationService,
    private connectWalletService: CrowdWalletService
  ) {}

  transform(hash: string, chainId: number) {
    if (!hash || !chainId) {
      this.notificationService.error('Error', 'Invalid transaction hash');
      return;
    }

    return this.connectWalletService.getScanTransactionUrl(hash, chainId);
  }
}
