"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Amates = void 0;
const networks_1 = require("../utils/networks");
exports.Amates = {
  Contracts: {
    networks: {
      [networks_1.Networks.POLYGON_MAINNET]: {
        plansFactoryAddress: '0x4241251A74D388D76711800898977f6000B169B4',
        NFTContractAddress: '0x1a90710401d632e2988116482E0431D668c72813'
      }
    }
  }
};
