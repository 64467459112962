import { CommonModule } from '@angular/common';
import {
  EnvironmentProviders,
  ModuleWithProviders,
  NgModule,
  Provider
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { registerLocaleData } from '@angular/common';
import { AntDesignModule } from './components/ant-design/ant-design.module';
import { SafePipe } from './pipe/safe.pipe';
import { CustomMaskPipe } from './pipe/customMask.pipe';
import { IconsProviderModule } from './components/icons-provider/icons-provider.module';
import en from '@angular/common/locales/en';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { CheckPatternPipe } from './pipe/checkPatternPipe.pipe';
import { SwiperModule } from 'swiper/angular';
import { ScanTransactionUrlPipe } from './pipe/getScanTransactionUrl.pipe';
import { AmountToDisplay } from './pipe/amountToDisplay.pipe';
import { ConvertToTitleCasePipe } from './pipe/convertToTitleCase.pipe';
import { PasswordModalComponent } from './components/password-modal/password-modal.component';

const IMPORTS = [
  AntDesignModule,
  CommonModule,
  ReactiveFormsModule,
  HttpClientModule,
  CommonModule,
  ReactiveFormsModule,
  IconsProviderModule,
  FormsModule,
  NgxMaskDirective,
  NgxMaskPipe,
  SwiperModule
];

const Declarations = [
  SafePipe,
  CustomMaskPipe,
  CheckPatternPipe,
  ScanTransactionUrlPipe,
  AmountToDisplay,
  ConvertToTitleCasePipe,
  PasswordModalComponent
];

const EXPORT = [
  AntDesignModule,
  FormsModule,
  CommonModule,
  ReactiveFormsModule,
  HttpClientModule,
  CommonModule,
  IconsProviderModule,
  SafePipe,
  CustomMaskPipe,
  NgxMaskDirective,
  NgxMaskPipe,
  CheckPatternPipe,
  SwiperModule,
  ScanTransactionUrlPipe,
  AmountToDisplay,
  ConvertToTitleCasePipe,
  PasswordModalComponent
];

const PROVIDERS: (Provider | EnvironmentProviders)[] = [provideNgxMask()];

const EntryComponents = [];

registerLocaleData(en);

@NgModule({
  imports: IMPORTS,
  declarations: Declarations,
  exports: EXPORT,
  providers: PROVIDERS
})
export class SharedModule {
  public static forRoot(): ModuleWithProviders<any> {
    return {
      ngModule: SharedModule
    };
  }
}
