// src/app/custom-mask.pipe.ts
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'checkPattern'
})
export class CheckPatternPipe implements PipeTransform {
  transform(value: string, pattern: string): boolean {
    if (!value) {
      return false;
    }

    return value.match(pattern) ? true : false;
  }
}
