export enum ReportStatus {
  PENDING = 'Pending',
  RUNNING = 'RUNNING',
  FINISHED = 'FINISHED',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR'
}

export interface IReport {
  created_at: string;
  cron_job: string;
  job_id: 48;
  name: string;
  nftTokenId: null;
  paused: true;
  room_id: string;
  task_date_done: string;
  task_result: string;
  task_status?: ReportStatus;
  task_traceback: null;

  cron_jobToDisplay?: string;
  taskDateToDisplay?: string;
}

export interface IExecution_graph {
  name: string;
  cron_job: string;
  nodes: INodes[];
}

export interface INodes {
  id: string;
  type: string;
  adjacent: string[];
  params: IParams;
}

export interface IParams {
  content_type: string;
  content_text: string;
  chat_id: string;
}

export interface IReportConfig {
  channel: {
    email: boolean;
    discord: boolean;
    telegram: boolean;
  };
  format: {
    textLayout: boolean;
    item2: boolean;
    item3: boolean;
  };
  EveryExecution: boolean;
  OnlyTXResults: boolean;
  OnceDay: boolean;
}

export class ReportConfig implements IReportConfig {
  channel = {
    email: false,
    discord: false,
    telegram: false
  };
  format = {
    textLayout: false,
    item2: false,
    item3: false
  };
  EveryExecution = false;
  OnlyTXResults = false;
  OnceDay = false;
}
