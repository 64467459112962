import { Injectable } from '@angular/core';
import { DeviceType, NDDClientInfoServiceImpl } from './client-info';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class ResponsiveService {
  private static $isMobile = new BehaviorSubject<DeviceType>(
    DeviceType.DESKTOP
  );

  constructor(protected clientInfoServiceImpl: NDDClientInfoServiceImpl) {}

  isMobileChanged(data: DeviceType) {
    ResponsiveService.$isMobile.next(data);
  }

  get isMobile(): Observable<DeviceType> {
    return ResponsiveService.$isMobile.asObservable();
  }
}
