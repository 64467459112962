import { Injectable } from '@angular/core';
import { BigNumber, Contract } from 'ethers';
import { NFTCONTRACT } from '../abis/nft';
import {
  AmatesCrowdToken,
  IMintAmatesNFTReqMdl,
  IMintAmatesNFTRespMdl,
  IPlan,
  MintStatus,
  MintSteps
} from '../models';
import {
  Amates,
  Conversion,
  CrowdToken,
  MainNetworksById,
  PriceService
} from '@crowdswap/constant';
import { PLANS_FACTORY } from '../abis/plansFactory';
import { formatNumber, NumberType } from '@uniswap/conedison/format.js';
import { CrowdWalletService } from './crowd-wallet.service';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

export const Mint_Address = [
  environment.WalletUrl,
  'account/mint-amates-nft?mustExecute=true'
].join('/');

@Injectable()
export class NftService {
  private readonly marketAssetsPath = 'assets/media/market/';
  public nftAddress = Amates.Contracts.networks[137].NFTContractAddress;
  public nftAbi = NFTCONTRACT;
  public planFactoryAbi = PLANS_FACTORY;
  public planFactoryAddress =
    Amates.Contracts.networks[137].plansFactoryAddress;

  constructor(
    private readonly priceService: PriceService,
    private readonly http: HttpClient,
    private readonly crowdWalletService: CrowdWalletService
  ) {}

  private getContract(address: string, abi: any) {
    return new Contract(
      address,
      abi,
      this.crowdWalletService.getNetworkProvider(137)
    );
  }

  async createPlan() {
    const contract = this.getContract(
      this.planFactoryAddress,
      this.planFactoryAbi
    );
    // Define the parameters for the new plan
    const planName = 'Striker';
    const minPower = 35;
    const maxPower = 40;
    const minSpeed = 55;
    const maxSpeed = 80;
    const minIntelligence = 35;
    const maxIntelligence = 45;
    const minAgility = 40;
    const maxAgility = 55;
    const price = 7;
    const imageUrl = 'https://asset.crowdswap.org/amates/striker.png';

    try {
      const transaction = await contract.populateTransaction.createPlan(
        planName,
        minPower,
        maxPower,
        minSpeed,
        maxSpeed,
        minIntelligence,
        maxIntelligence,
        minAgility,
        maxAgility,
        price,
        imageUrl
      );
      // Call the createPlan method

      if (transaction) {
        transaction.gasLimit = BigNumber.from(0x069d63);
        transaction.from = this.crowdWalletService.getWalletAddress();
        await this.crowdWalletService.sendTransaction(transaction);
      }
      const tx = await contract.createPlan(
        planName,
        minPower,
        maxPower,
        minSpeed,
        maxSpeed,
        minIntelligence,
        maxIntelligence,
        minAgility,
        maxAgility,
        price,
        imageUrl
      );

      console.log('Transaction submitted. Waiting for confirmation...');

      // Wait for the transaction to be confirmed
      await tx.wait();

      console.log('Plan created successfully!');
    } catch (error) {
      console.error('Failed to create plan:', error);
    }
  }

  async updatePlan() {
    const contract = this.getContract(
      this.planFactoryAddress,
      this.planFactoryAbi
    );
    // Define the parameters for the new plan
    const planName = 'Striker';
    const minPower = 35;
    const maxPower = 40;
    const minSpeed = 55;
    const maxSpeed = 80;
    const minIntelligence = 35;
    const maxIntelligence = 45;
    const minAgility = 40;
    const maxAgility = 55;
    const price = 7;
    const imageUrl = 'https://asset.crowdswap.org/amates/striker.png';

    try {
      const transaction = await contract.populateTransaction.updatePlan(
        1,
        planName,
        minPower,
        maxPower,
        minSpeed,
        maxSpeed,
        minIntelligence,
        maxIntelligence,
        minAgility,
        maxAgility,
        price,
        imageUrl
      );
      // Call the createPlan method

      if (transaction) {
        transaction.gasLimit = BigNumber.from(0x069d63);
        transaction.from = this.crowdWalletService.getWalletAddress();
        await this.crowdWalletService.sendTransaction(transaction);
      }
      const tx = await contract.updatePlan(
        1,
        planName,
        minPower,
        maxPower,
        minSpeed,
        maxSpeed,
        minIntelligence,
        maxIntelligence,
        minAgility,
        maxAgility,
        price,
        imageUrl
      );

      console.log('Transaction submitted. Waiting for confirmation...');

      // Wait for the transaction to be confirmed
      await tx.wait();

      console.log('Plan created successfully!');
    } catch (error) {
      console.error('Failed to create plan:', error);
    }
  }

  async mintAssistant(planId: number) {
    const assistantPlan = await this.getPlanById(planId);

    const contract = this.getContract(
      this.planFactoryAddress,
      this.planFactoryAbi
    );

    try {
      const transaction = await contract.populateTransaction.buyNft([planId]);
      // Call the createPlan method

      if (transaction) {
        transaction.gasLimit = BigNumber.from(0x069d63);
        transaction.from = this.crowdWalletService.getWalletAddress();
        await this.crowdWalletService.sendTransaction(transaction);
      }
      const tx = await contract.mintFromPlan([assistantPlan.index]);

      console.log('Transaction submitted. Waiting for confirmation...');

      // Wait for the transaction to be confirmed
      await tx.wait();

      console.log('Plan created successfully!');
    } catch (error) {
      console.error('Failed to create plan:', error);
    }
  }

  public async mintAmatesNFT(planId: number): Promise<IMintAmatesNFTRespMdl> {
    const userNFTs = await this.getUserNFTs();

    try {
      const data: IMintAmatesNFTReqMdl = {
        planId
      };

      const respData = <IMintAmatesNFTRespMdl>(
        await this.http
          .post(Mint_Address, data, this.crowdWalletService.setHeader())
          .toPromise()
      );

      if (typeof respData !== 'string') {
        return respData;
      }

      let result: IMintAmatesNFTRespMdl = {
        status: MintStatus.success,
        transactionHash: respData
      };

      return result;
    } catch (error) {
      console.log(error);
      const newUserNFTs = await this.getUserNFTs();
      if (newUserNFTs.length > userNFTs.length) {
        return {
          status: MintStatus.success,
          transactionHash: undefined
        };
      }

      return { msg: 'Access Denied', status: MintStatus.error };
    }
  }

  async getAllAssistantPlans(): Promise<IPlan[]> {
    try {
      const contract = this.getContract(
        this.planFactoryAddress,
        this.planFactoryAbi
      );

      const result = await contract.getAllPlans();

      const plans: IPlan[] = result.map((item, index) => {
        const price = Conversion.convertStringFromDecimal(
          item.price.toString(),
          6
        );

        return {
          planId: parseInt(item.planId.toString()),
          name: item.name,
          cardImgPath: [
            this.marketAssetsPath,
            item.name.toLowerCase(),
            '-card.png'
          ].join(''),
          bannerImgPath: [
            this.marketAssetsPath,
            item.name.toLowerCase(),
            '.png'
          ].join(''),
          agility: {
            max: item.agility.max.toString(),
            min: item.agility.min.toString()
          },
          intelligence: {
            max: item.intelligence.max.toString(),
            min: item.intelligence.min.toString()
          },
          power: {
            max: item.power.max.toString(),
            min: item.power.min.toString()
          },
          speed: {
            max: item.speed.max.toString(),
            min: item.speed.min.toString()
          },
          mintSteps: MintSteps.Init,
          Price: price,
          PriceToDisplay: Conversion.adjustFraction(parseFloat(price), 6),
          // todo
          automations: 0,
          fuelEfficiency: 0,
          repairable: false,
          qtyMint: 1,
          shareable: false,
          tearFactor: 0,
          walletAddress: 'test'
        } as IPlan;
      });
      
      return plans;
    } catch (error) {
      console.log(error);
      return [];
    }
  }

  public async getPlanById(planId: number) {
    const contract = this.getContract(
      this.planFactoryAddress,
      this.planFactoryAbi
    );
    const plan = await contract.plans(planId);

    return plan;
  }

  async getUserNFTs(): Promise<BigNumber[]> {
    try {
      const userAddress = this.crowdWalletService.getWalletAddress();
      const nftContract = this.getContract(this.nftAddress, this.nftAbi);
      const userNFTs = <BigNumber[]>await nftContract.getUserNFTs(userAddress);

      return userNFTs;
    } catch (error) {
      console.error('Error fetching getUserNFTs:', error);
      return [];
    }
  }

  async getUserAssistants(): Promise<IPlan[]> {
    try {
      const nftContract = this.getContract(this.nftAddress, this.nftAbi);
      const userNFTs = await this.getUserNFTs();

      const userAssistants: IPlan[] = [];
      for (const nft of userNFTs) {
        const element = await nftContract.nftAttributes(nft);

        const planDetails = await this.getPlanById(element.planId);

        const price = Conversion.convertStringFromDecimal(
          planDetails.price.toString(),
          6
        );

        userAssistants.push({
          name: planDetails.name,
          power: { active: element.power.toString() },
          speed: { active: element.speed.toString() },
          intelligence: { active: element.intelligence.toString() },
          agility: { active: element.agility.toString() },
          cardImgPath: [
            this.marketAssetsPath,
            planDetails.name.toLowerCase(),
            '-card.png'
          ].join(''),
          bannerImgPath: [
            this.marketAssetsPath,
            planDetails.name.toLowerCase(),
            '.png'
          ].join(''),
          planId: parseInt(element.planId.toString()),
          tokenId: parseInt(nft.toString()),
          Price: price,
          PriceToDisplay: Conversion.adjustFraction(parseFloat(price), 6),
          // todo
          automations: 0,
          fuelEfficiency: 0,
          repairable: false,
          qtyMint: 1,
          shareable: false,
          tearFactor: 0,
          walletAddress: 'test',
          mintSteps: MintSteps.Init
        } as IPlan);
      }

      return userAssistants;
    } catch (error) {
      console.error('Error fetching NFT details:', error);
      return [];
    }
  }

  public async addBalanceToTokens(
    tokens: any,
    withBalance: boolean = false
  ): Promise<AmatesCrowdToken[]> {
    const promises: any[] = [];
    let result: any[] = [];

    for (const chainId in MainNetworksById) {
      if (environment.ACTIVE_NETWORK.includes(chainId)) {
        let tempTokens = tokens.filter(
          (token: CrowdToken) => token.chainId.toString() === chainId
        );
        promises.push(this.crowdWalletService.addBalanceToTokens(tempTokens));
      }
    }

    let promiseResult = await Promise.allSettled(promises);
    promiseResult.forEach((item, index, array) => {
      if (item && item.status === 'fulfilled' && item.value.length > 0) {
        const list = item.value.filter((x) => parseFloat(x.balance) !== 0);
        result.push(...list);
      }
    });

    if (withBalance) {
      result = result.filter((token: CrowdToken) => {
        return token.balance && token.balance != '' && +token.balance > 0;
      });
    }

    result.forEach(async (item: AmatesCrowdToken) => {
      try {
        item.price = await this.priceService.getPrice(
          item,
          this.crowdWalletService.getNetworkProvider(item.chainId)
        );
        item.value = (
          parseFloat(item.balance) * parseFloat(item.price ?? '0')
        ).toString();
        item.balanceToDisplayInUSDT = formatNumber(
          parseFloat(item.value),
          NumberType.FiatTokenPrice
        );
      } catch (e) {
        console.log(e);
      }
    });

    return result;
  }
}
