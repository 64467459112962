import { CrosschainFeeType, CrowdToken } from '@crowdswap/constant';

export interface IGetAddressReqMdl {
  email: string;
  password: string;
}

export interface IGetTransferReqMdl {
  token: string;
  destination: string;
  amount: string;
  chainId: number;
  gasToken?: string;
}

export interface IGetSwapReqMdl {
  tokenInAddress: string;
  tokenOutAddress: string;
  amountIn: string;
  chainId: number;
  gasToken?: string;
}

export interface IExecuteReqMdl {
  transactions: any;
  chainId: number;
  gasLimit: string;
  gasPrice: string;
  gasToken?: string;
}

export enum SmartWalletConfirmation {
  Transfer = 'Transfer',
  Swap = 'Swap'
}

export interface ISmartWalletConfirmationRespMdl {
  type: SmartWalletConfirmation;
}

export interface IGetTransferRespMdl {
  request: {
    transactions: [
      {
        to: string;
        data: string;
        value: string;
      }
    ];
    chainId: number;
    gasLimit: string;
    gasPrice: string;
  };
  fees: IFee[];
}

export interface IFee {
  token: CrowdToken;
  amount: string;
  amountInUSDT?: number;
  amountInUSDTToDisplay?: string;
  type?: CrosschainFeeType;
}

export interface AmatesCrowdToken extends CrowdToken {
  balanceToDisplayInUSDT: string;
}

export enum WalletSidebarType {
  Receive = 'Receive',
  Send = 'Send'
}

export interface IExecuteTransfer {
  token: string;
  destination: string;
  amount: string;
  chainId: number;
  gasToken?: string;
}

export interface IReceiverInfo {
  networkCoinBalance: string | undefined;
  firstTransactionDate: string | undefined;
  lastTransactionDate: string | undefined;
  lastTransactionType: string | undefined;
  lastTransactionValue: string | undefined;
  lastTransactionToken: string | undefined;
  confirmed: boolean;
  tokenBalance: string | undefined;
  hasActivityInLastThreeMonths: boolean;
  hasTransactionInLastThreeMonths: boolean;
  isWalletOld: boolean;
  isWalletAddress: boolean;
  loading: boolean;
}

export class ReceiverInfo implements IReceiverInfo {
  networkCoinBalance = undefined;
  firstTransactionDate = undefined;
  lastTransactionDate = undefined;
  lastTransactionType = undefined;
  lastTransactionValue = undefined;
  lastTransactionToken = undefined;
  confirmed = false;
  tokenBalance = undefined;
  hasActivityInLastThreeMonths = false;
  hasTransactionInLastThreeMonths = false;
  isWalletOld = false;
  isWalletAddress = false;
  loading = true;
}
