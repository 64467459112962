import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { throwError } from 'rxjs';
import { IExecuteTransfer, IGetAddressReqMdl, IUserDetail } from '../models';
import { StorageService } from './storage.service';
import { Web3Service } from './web3.service';
import { NetworksService } from './networks.service';
import { Router } from '@angular/router';
import { NzNotificationService } from 'ng-zorro-antd/notification';

@Injectable()
export class CrowdWalletService extends Web3Service {
  public SEARCH_NOTIFIER_TIME = 1000;
  // public userData = new UserDetail();
  private static userPassword: string;

  constructor(
    private readonly http: HttpClient,
    private readonly storage: StorageService,
    private readonly router: Router,
    protected networksService: NetworksService,
    private readonly nzNotificationService: NzNotificationService
  ) {
    super(networksService);
    this.initProvider(this.userDetail.address);
  }

  get userDetail(): IUserDetail {
    const data = this.storage.getItemFromLocalStorage('user');
    return data ? JSON.parse(data) : {};
  }

  set userDetail(data: IUserDetail) {
    const currentData = this.userDetail;
    const updatedData = { ...currentData, ...data };
    this.storage.setItemToLocalStorage('user', updatedData);
  }

  get userPassword(): string {
    return CrowdWalletService.userPassword;
  }

  set userPassword(password: string) {
    CrowdWalletService.userPassword = password;
  }

  public async signIn(
    data: IGetAddressReqMdl,
    updatePass?: boolean
  ): Promise<string | undefined> {
    try {
      const url = [environment.WalletUrl, 'auth/sign-in'].join('/');
      const result = (await this.http.post(url, data).toPromise()) as {
        address: string;
        access_token: string;
        twoFactorAuthenticationEnabled: boolean;
        otpGuardedActions: string[];
      };

      if (result?.address) {
        if (!updatePass) {
          this.initProvider(result.address);
        }

        this.userPassword = data.password;
        this.userDetail = result;

        return result.address;
      } else {
        return undefined;
      }
    } catch (err: any) {
      console.error(`Cannot fetch user address`);
      return undefined;
    }
  }

  public async isUserExistOrSendCode(emailAddress: string): Promise<boolean> {
    try {
      const url = [
        environment.WalletUrl,
        'auth/send-verification-code'
      ].join('/');
      const data = { email: emailAddress, purpose: 'amates-register' };
      this.userDetail = { email: emailAddress };

      let userObj = (await this.http.post(url, data).toPromise()) as {
        result: string;
      };

      return userObj.result !== 'OK';
    } catch (err: any) {
      if (
        err.error.statusCode === 302 &&
        err.error.message === 'User Already Exists'
      ) {
        return true;
      }
      console.error(`Cannot send verification code to the server`);
      throw err;
    }
  }

  public async isVerificationCodeCorrect(
    emailAddress: string,
    verificationCode: string
  ) {
    try {
      const url = [
        environment.WalletUrl,
        'auth/is-verification-code-correct'
      ].join('/');
      const data = { email: emailAddress, verificationCode: verificationCode };

      let userObj = (await this.http.post(url, data).toPromise()) as {
        result: boolean;
      };

      return userObj.result;
    } catch (err: any) {
      console.error(`Cannot send verification code to the server`);
      return false;
    }
  }

  public async registerUser(
    emailAddress: string,
    verificationCode: string,
    password: string
  ) {
    try {
      const url = [environment.WalletUrl, 'auth/register'].join('/');
      const data = {
        email: emailAddress,
        verificationCode: verificationCode,
        password: password
      };

      let userObj = (await this.http.post(url, data).toPromise()) as {
        result: string;
      };

      return userObj.result === 'OK';
    } catch (err: any) {
      console.log(`Cannot send verification code to the server`);
      return false;
    }
  }

  public async enable2FA(
    password: string | null,
    secret2FA: string,
    otp: string
  ): Promise<void> {
    const url = [environment.WalletUrl, 'auth/enable-2fa'].join('/');
    const data = {
      email: this.userDetail.email,
      password: password,
      secret2fa: secret2FA,
      otpCode: otp
    };

    await this.http.post(url, data).toPromise();
    this.userDetail = {
      twoFactorAuthenticationEnabled: true,
      otpGuardedActions: ['transfer'],
      access_token:  this.userDetail.access_token
    };
  }

  public logOut() {
    this.storage.removeItemFromLocalStorage('user');
    this.userPassword = '';
    this.router.navigate(['/', 'login-register']);
  }

  public async executeTransfer(
    data: IExecuteTransfer,
    otp?: string
  ): Promise<{ hash: string } | undefined> {
    try {
      const url = [environment.WalletUrl, 'account/execute-transfer'].join(
        '/'
      );

      const transfer = await this.http
        .post<{ hash: string } | undefined>(url, data, this.setHeader(otp))
        .toPromise();

      if (!transfer) {
        throwError(transfer);
      }

      return transfer;
    } catch (error: any) {
      console.error('Transaction Failed:', error);
      this.nzNotificationService.error(
        'Transaction Failed:',
        'We encountered an issue while processing your transfer. Please check your account balance, ensure the recipient details are correct, and try again. If the issue persists, contact customer support for assistance.',
        {
          nzPlacement: 'bottomRight'
        }
      );
      return;
    }
  }

  setHeader(otp: string = '') {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.userDetail.access_token,
      'x-otp-token': otp
    });

    return { headers: headers };
  }
}
