import { Injectable } from '@angular/core';
import { io, Socket } from 'socket.io-client';
import { BehaviorSubject, finalize, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CrowdWalletService } from './crowd-wallet.service';
import { Router } from '@angular/router';
import {
  ChannelType,
  IAmatesReport,
  IMessage,
  IMessageHistory,
  MessageButtonType,
  MessageRole
} from '../models';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { HttpClient } from '@angular/common/http';
import { report } from 'stacktrace-js';

export const HistoryURL = [
  environment.AMATES_AI_BASEURL,
  'get_chat_history/{0}'
].join('/');
export const ClearChatURL = [environment.AMATES_AI_BASEURL, 'clear'].join('/');
@Injectable({
  providedIn: 'root'
})
export class SocketService {
  private socket: Socket;
  private room = '';
  private messagesSubject: BehaviorSubject<IMessage[]> = new BehaviorSubject<
    IMessage[]
  >([]);
  public messages$ = this.messagesSubject.asObservable();

  private amatesReportSubject: BehaviorSubject<IAmatesReport> =
    new BehaviorSubject<IAmatesReport>({
      train: {
        progress: 0,
        report: [],
        finalized: false
      },
      build: {
        progress: 0,
        report: [],
        finalized: false
      }
    });
  public amatesReport$ = this.amatesReportSubject.asObservable();

  private static userMessageTyped = '';
  public loading = true;
  public channelType = new BehaviorSubject<ChannelType>(ChannelType.BUILD);
  public inputPassword = new BehaviorSubject<boolean>(false);

  private suggestedRepliesSubject = new BehaviorSubject<string[]>([]);
  public suggestedReplies$ = this.suggestedRepliesSubject.asObservable();

  constructor(
    private connectWalletService: CrowdWalletService,
    private nzNotificationService: NzNotificationService,
    private router: Router,
    private http: HttpClient
  ) {
    this.socket = io(environment.AMATES_AI_BASEURL);

    const userEmail = this.connectWalletService.userDetail.email;
    if (!userEmail) {
      this.router.navigate(['/', 'login-register']);
      return;
    }

    this.room = userEmail;

    this.emitEvent('join', { room: this.room, type: ChannelType.BUILD });

    this.listenMessages();
  }

  set userMessageTyped(value: string) {
    SocketService.userMessageTyped = value;
  }

  get userMessageTyped(): string {
    return SocketService.userMessageTyped;
  }

  get roomId(): string {
    return this.room;
  }

  // Method to emit events to the server
  emitEvent(eventName: string, data: any) {
    this.socket.emit(eventName, data);
  }

  // Method to listen to events from the server
  listenToEvent(eventName: string): Observable<any> {
    return new Observable((subscriber) => {
      this.socket.on(eventName, (data) => {
        subscriber.next(data);
      });

      // Cleanup on unsubscribe
      return () => {
        this.socket.off(eventName);
      };
    });
  }

  sendMessage(message: string, channelType: ChannelType) {
    this.loading = true;
    this.socket.send({ message, room: this.room, type: channelType });
  }

  // Optionally, add methods to disconnect/reconnect or handle other specific Socket.IO features
  disconnect() {
    if (this.socket) {
      this.socket.disconnect();
    }
  }

  listenMessages() {
    this.listenToEvent('message').subscribe((resp: IMessage) => {
      if (typeof resp === 'string') {
        resp = {
          message: resp,
          role: MessageRole.system,
          type: ChannelType.ALL
        };
      }

      const message: IMessage[] = [];

      switch (resp.role) {
        case MessageRole.amates: {
          this.handleSuggestedReplies(resp.suggested_replies ?? []);

          if (resp.report?.build) {
            resp.report.build.progress =
              (resp.report.build.progress ?? 0) >= 25
                ? resp.report.build.progress
                : 20;
            this.amatesProgressChanged(resp);
          }
          if (resp.report?.train) {
            resp.report.train.progress =
              (resp.report.train.progress ?? 0) >= 25
                ? resp.report.train.progress
                : 20;
            this.amatesProgressChanged(resp);
          }

          const switchtabRegex = new RegExp(/{switchtab}/, 'i');
          resp.hasButton = switchtabRegex.test(resp.message);
          resp.buttonType = MessageButtonType.switchtab;
          resp.messageList = resp.message.split('{switchtab}');

          const inputPasswordRegex = new RegExp(/password/, 'i');
          this.inputTypeChanged(inputPasswordRegex.test(resp.message));

          message.push(resp);
          break;
        }

        case MessageRole.system: {
          if (resp.message.includes('Someone has joined')) {
            if (resp.report?.build || resp.report?.train) {
              resp.report.build.progress =
                (resp.report.build.progress ?? 0) >= 25
                  ? resp.report.build.progress
                  : 20;

              resp.report.train.progress =
                (resp.report.train.progress ?? 0) >= 25
                  ? resp.report.train.progress
                  : 20;

              const buildMessage: IMessage = {
                ...resp,
                report: resp.report
              };
              message.push(buildMessage);
              this.amatesProgressChanged(buildMessage);

              const trainMessage: IMessage = {
                role: MessageRole.amates,
                report: resp.report,
                type: ChannelType.TRAIN,
                message: 'What agent do you want to train today?'
              };
              message.push(trainMessage);
              this.amatesProgressChanged(trainMessage);
            }
          } else {
            this.nzNotificationService.error('Error', resp.message, {
              nzPlacement: 'bottomRight'
            });
            message.push(resp);
          }

          break;
        }
      }

      this.loading = false;
      this.addMessage(message);
    });
  }

  addMessage(newMessage: IMessage[]) {
    newMessage.map((m) => m.message.replace(/\n/g, '<br />'));

    const currentMessages = this.messagesSubject.value;
    this.messagesSubject.next([...currentMessages, ...newMessage]);
  }

  getAllMessage(): IMessage[] {
    return this.messagesSubject.value;
  }

  amatesProgressChanged(resp: IMessage) {
    const currentAmatesReport = this.amatesReportSubject.value;
    if (!resp.report) {
      return;
    }

    switch (resp.type) {
      case ChannelType.TRAIN:
        this.amatesReportSubject.next({
          ...currentAmatesReport,
          train: resp.report.train
        });
        break;

      case ChannelType.BUILD:
      default:
        this.amatesReportSubject.next({
          ...currentAmatesReport,
          build: resp.report.build
        });
        break;
    }
  }

  channelTypeChanged(data: ChannelType) {
    this.channelType.next(data);
  }

  inputTypeChanged(data: boolean) {
    this.inputPassword.next(data);
  }

  async loadMessagesHistory() {
    try {
      const result = <IMessageHistory[]>(
        await this.http.get(HistoryURL.replace('{0}', this.room)).toPromise()
      );
      const currentMessages = this.messagesSubject.value;
      const history: IMessage[] = result.map((m) => {
        const message: IMessage = {
          message: m.message.replace(/\n/g, '<br />'),
          role: m.role === 'bot' ? MessageRole.amates : m.role,
          type: ChannelType.BUILD,
          report: undefined
        };

        if (message.role === MessageRole.amates) {
          const regex = new RegExp(/{switchtab}/, 'i');
          message.hasButton = regex.test(message.message);
          message.buttonType = MessageButtonType.switchtab;
          message.messageList = message.message.split('{switchtab}');
        }

        return message;
      });

      this.messagesSubject.next([...history, ...currentMessages]);
    } catch (err: any) {
      return [];
    }
  }

  async clearChat() {
    try {
      this.loading = true;
      const trainUrl = [ClearChatURL, this.room, ChannelType.TRAIN].join('/');
      const buildUrl = [ClearChatURL, this.room, ChannelType.BUILD].join('/');

      const trainResult = await this.http.post(trainUrl, {}).toPromise();
      const buildResult = await this.http.post(buildUrl, {}).toPromise();

      this.loading = false;

      if (trainResult && buildResult) {
        this.handleSuggestedReplies([
          'I want to build a swap assistant.',
          'I want to build a telegram notifier assistant.'
        ]);

        const message = [
          {
            message: 'New Chat Started',
            role: MessageRole.system,
            type: ChannelType.TRAIN,
            report: {
              build: {
                progress: 20,
                report: [],
                finalized: false
              },
              train: {
                progress: 20,
                report: [],
                finalized: false
              }
            }
          },
          {
            message: 'New Chat Started',
            role: MessageRole.system,
            type: ChannelType.BUILD,
            report: {
              build: {
                progress: 20,
                report: [],
                finalized: false
              },
              train: {
                progress: 20,
                report: [],
                finalized: false
              }
            }
          }
        ];
        this.messagesSubject.next([...this.messagesSubject.value, ...message]);
        this.amatesProgressChanged(message[0]);
        this.amatesProgressChanged(message[1]);
      }
    } catch (error) {
      this.loading = false;
    }
  }

  handleSuggestedReplies(suggestedReplies: string[]) {
    this.suggestedRepliesSubject.next(suggestedReplies);
  }
}
