export enum ChannelType {
  BUILD = 'build',
  TRAIN = 'train',
  ALL = 'all'
}

export enum MobileDesignTab {
  Chat,
  Bot
}

export enum BotStepsStatus {
  Done,
  Pending
}

export interface IBotSteps {
  step: string;
  status: BotStepsStatus;
  statusTitle?: string;
}
