import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { IReport } from '../models';
import { HttpClient } from '@angular/common/http';
import { NzNotificationService } from 'ng-zorro-antd/notification';

@Injectable()
export class ReportService {
  private AllReportURL = [environment.AMATES_AI_BASEURL, 'job/all/report'].join(
    '/'
  );

  constructor(
    private http: HttpClient,
    private nzNotificationService: NzNotificationService
  ) {}

  public async getAllReport(): Promise<IReport[] | []> {
    try {
      const result = <IReport[]>(
        await this.http.get(this.AllReportURL).toPromise()
      );
      return result;
    } catch (err: any) {
      console.error(`Cannot fetch user reports`);
      this.nzNotificationService.error('Error', 'Cannot fetch user reports', {
        nzPlacement: 'bottomRight'
      });
      return [];
    }
  }

  // public async getReport(data: IReport): Promise<any> {
  //   try {
  //     const url = [this.ReportURL, data.room_id].join('/');

  //     return await this.http.get(url).toPromise();
  //   } catch (err: any) {
  //     console.error(`Cannot fetch the room report data.`);
  //   }
  // }
}
