import { Injectable } from '@angular/core';
import QRCode from 'qrcode';

@Injectable()
export class QRCodeService {
  constructor() {}

  generateBarcode(el?: HTMLCanvasElement, data?: string) {
    if (el && data) {
      QRCode.toCanvas(
        el,
        data,
        { errorCorrectionLevel: 'H', margin: '1' },
        (error) => {
          if (error) {
            console.error('Error generating QR Code:', error);
          }
        }
      );
    }
  }

  exportCanvas(el) {
    const dataURL = el.toDataURL('image/png');
    const link = document.createElement('a');
    link.href = dataURL;
    link.download = 'canvas-image.png';
    link.click();
  }
}
