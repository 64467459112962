export const PLANS_FACTORY = [
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'address',
        name: 'previousAdmin',
        type: 'address'
      },
      {
        indexed: false,
        internalType: 'address',
        name: 'newAdmin',
        type: 'address'
      }
    ],
    name: 'AdminChanged',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'beacon',
        type: 'address'
      }
    ],
    name: 'BeaconUpgraded',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'uint8',
        name: 'version',
        type: 'uint8'
      }
    ],
    name: 'Initialized',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'address',
        name: 'buyer',
        type: 'address'
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'tokenId',
        type: 'uint256'
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'planId',
        type: 'uint256'
      }
    ],
    name: 'NFTPurchased',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'previousOwner',
        type: 'address'
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'newOwner',
        type: 'address'
      }
    ],
    name: 'OwnershipTransferred',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'uint256',
        name: 'planId',
        type: 'uint256'
      },
      {
        indexed: false,
        internalType: 'string',
        name: 'name',
        type: 'string'
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'powerMin',
        type: 'uint256'
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'powerMax',
        type: 'uint256'
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'speedMin',
        type: 'uint256'
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'speedMax',
        type: 'uint256'
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'intelligenceMin',
        type: 'uint256'
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'intelligenceMax',
        type: 'uint256'
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'agilityMin',
        type: 'uint256'
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'agilityMax',
        type: 'uint256'
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'price',
        type: 'uint256'
      },
      {
        indexed: false,
        internalType: 'string',
        name: 'imageUrl',
        type: 'string'
      }
    ],
    name: 'PlanCreated',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'uint256',
        name: 'planId',
        type: 'uint256'
      }
    ],
    name: 'PlanDeleted',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'uint256',
        name: 'planId',
        type: 'uint256'
      },
      {
        indexed: false,
        internalType: 'string',
        name: 'name',
        type: 'string'
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'powerMin',
        type: 'uint256'
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'powerMax',
        type: 'uint256'
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'speedMin',
        type: 'uint256'
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'speedMax',
        type: 'uint256'
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'intelligenceMin',
        type: 'uint256'
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'intelligenceMax',
        type: 'uint256'
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'agilityMin',
        type: 'uint256'
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'agilityMax',
        type: 'uint256'
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'price',
        type: 'uint256'
      },
      {
        indexed: false,
        internalType: 'string',
        name: 'imageUrl',
        type: 'string'
      }
    ],
    name: 'PlanUpdated',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'implementation',
        type: 'address'
      }
    ],
    name: 'Upgraded',
    type: 'event'
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256'
      }
    ],
    name: 'allPlanIds',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'planId',
        type: 'uint256'
      }
    ],
    name: 'buyNft',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [],
    name: 'claimOwnership',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'string',
        name: 'name',
        type: 'string'
      },
      {
        internalType: 'uint256',
        name: 'powerMin',
        type: 'uint256'
      },
      {
        internalType: 'uint256',
        name: 'powerMax',
        type: 'uint256'
      },
      {
        internalType: 'uint256',
        name: 'speedMin',
        type: 'uint256'
      },
      {
        internalType: 'uint256',
        name: 'speedMax',
        type: 'uint256'
      },
      {
        internalType: 'uint256',
        name: 'intelligenceMin',
        type: 'uint256'
      },
      {
        internalType: 'uint256',
        name: 'intelligenceMax',
        type: 'uint256'
      },
      {
        internalType: 'uint256',
        name: 'agilityMin',
        type: 'uint256'
      },
      {
        internalType: 'uint256',
        name: 'agilityMax',
        type: 'uint256'
      },
      {
        internalType: 'uint256',
        name: 'price',
        type: 'uint256'
      },
      {
        internalType: 'string',
        name: 'imageUrl',
        type: 'string'
      }
    ],
    name: 'createPlan',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'planId',
        type: 'uint256'
      }
    ],
    name: 'deletePlan',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [],
    name: 'getAllPlans',
    outputs: [
      {
        components: [
          {
            internalType: 'string',
            name: 'name',
            type: 'string'
          },
          {
            components: [
              {
                internalType: 'uint256',
                name: 'min',
                type: 'uint256'
              },
              {
                internalType: 'uint256',
                name: 'max',
                type: 'uint256'
              }
            ],
            internalType: 'struct PlansFactory.AttributeRange',
            name: 'power',
            type: 'tuple'
          },
          {
            components: [
              {
                internalType: 'uint256',
                name: 'min',
                type: 'uint256'
              },
              {
                internalType: 'uint256',
                name: 'max',
                type: 'uint256'
              }
            ],
            internalType: 'struct PlansFactory.AttributeRange',
            name: 'speed',
            type: 'tuple'
          },
          {
            components: [
              {
                internalType: 'uint256',
                name: 'min',
                type: 'uint256'
              },
              {
                internalType: 'uint256',
                name: 'max',
                type: 'uint256'
              }
            ],
            internalType: 'struct PlansFactory.AttributeRange',
            name: 'intelligence',
            type: 'tuple'
          },
          {
            components: [
              {
                internalType: 'uint256',
                name: 'min',
                type: 'uint256'
              },
              {
                internalType: 'uint256',
                name: 'max',
                type: 'uint256'
              }
            ],
            internalType: 'struct PlansFactory.AttributeRange',
            name: 'agility',
            type: 'tuple'
          },
          {
            internalType: 'uint256',
            name: 'price',
            type: 'uint256'
          },
          {
            internalType: 'string',
            name: 'imageUrl',
            type: 'string'
          },
          {
            internalType: 'uint256',
            name: 'planId',
            type: 'uint256'
          }
        ],
        internalType: 'struct PlansFactory.PlanAttributes[]',
        name: '',
        type: 'tuple[]'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'planId',
        type: 'uint256'
      }
    ],
    name: 'getPlan',
    outputs: [
      {
        components: [
          {
            internalType: 'string',
            name: 'name',
            type: 'string'
          },
          {
            components: [
              {
                internalType: 'uint256',
                name: 'min',
                type: 'uint256'
              },
              {
                internalType: 'uint256',
                name: 'max',
                type: 'uint256'
              }
            ],
            internalType: 'struct PlansFactory.AttributeRange',
            name: 'power',
            type: 'tuple'
          },
          {
            components: [
              {
                internalType: 'uint256',
                name: 'min',
                type: 'uint256'
              },
              {
                internalType: 'uint256',
                name: 'max',
                type: 'uint256'
              }
            ],
            internalType: 'struct PlansFactory.AttributeRange',
            name: 'speed',
            type: 'tuple'
          },
          {
            components: [
              {
                internalType: 'uint256',
                name: 'min',
                type: 'uint256'
              },
              {
                internalType: 'uint256',
                name: 'max',
                type: 'uint256'
              }
            ],
            internalType: 'struct PlansFactory.AttributeRange',
            name: 'intelligence',
            type: 'tuple'
          },
          {
            components: [
              {
                internalType: 'uint256',
                name: 'min',
                type: 'uint256'
              },
              {
                internalType: 'uint256',
                name: 'max',
                type: 'uint256'
              }
            ],
            internalType: 'struct PlansFactory.AttributeRange',
            name: 'agility',
            type: 'tuple'
          },
          {
            internalType: 'uint256',
            name: 'price',
            type: 'uint256'
          },
          {
            internalType: 'string',
            name: 'imageUrl',
            type: 'string'
          },
          {
            internalType: 'uint256',
            name: 'planId',
            type: 'uint256'
          }
        ],
        internalType: 'struct PlansFactory.PlanAttributes',
        name: '',
        type: 'tuple'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: '_paymentToken',
        type: 'address'
      }
    ],
    name: 'initialize',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [],
    name: 'migratePlanIds',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [],
    name: 'nextPlanId',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [],
    name: 'nftContractAddress',
    outputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [],
    name: 'owner',
    outputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [],
    name: 'paymentToken',
    outputs: [
      {
        internalType: 'contract IERC20',
        name: '',
        type: 'address'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [],
    name: 'pendingOwner',
    outputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256'
      }
    ],
    name: 'plans',
    outputs: [
      {
        internalType: 'string',
        name: 'name',
        type: 'string'
      },
      {
        components: [
          {
            internalType: 'uint256',
            name: 'min',
            type: 'uint256'
          },
          {
            internalType: 'uint256',
            name: 'max',
            type: 'uint256'
          }
        ],
        internalType: 'struct PlansFactory.AttributeRange',
        name: 'power',
        type: 'tuple'
      },
      {
        components: [
          {
            internalType: 'uint256',
            name: 'min',
            type: 'uint256'
          },
          {
            internalType: 'uint256',
            name: 'max',
            type: 'uint256'
          }
        ],
        internalType: 'struct PlansFactory.AttributeRange',
        name: 'speed',
        type: 'tuple'
      },
      {
        components: [
          {
            internalType: 'uint256',
            name: 'min',
            type: 'uint256'
          },
          {
            internalType: 'uint256',
            name: 'max',
            type: 'uint256'
          }
        ],
        internalType: 'struct PlansFactory.AttributeRange',
        name: 'intelligence',
        type: 'tuple'
      },
      {
        components: [
          {
            internalType: 'uint256',
            name: 'min',
            type: 'uint256'
          },
          {
            internalType: 'uint256',
            name: 'max',
            type: 'uint256'
          }
        ],
        internalType: 'struct PlansFactory.AttributeRange',
        name: 'agility',
        type: 'tuple'
      },
      {
        internalType: 'uint256',
        name: 'price',
        type: 'uint256'
      },
      {
        internalType: 'string',
        name: 'imageUrl',
        type: 'string'
      },
      {
        internalType: 'uint256',
        name: 'planId',
        type: 'uint256'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [],
    name: 'proxiableUUID',
    outputs: [
      {
        internalType: 'bytes32',
        name: '',
        type: 'bytes32'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: '_nftContractAddress',
        type: 'address'
      }
    ],
    name: 'setNFTContractAddress',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: '_paymentToken',
        type: 'address'
      }
    ],
    name: 'setPaymentTokenAddress',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'newOwner',
        type: 'address'
      }
    ],
    name: 'transferOwnership',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'planId',
        type: 'uint256'
      },
      {
        internalType: 'string',
        name: 'name',
        type: 'string'
      },
      {
        internalType: 'uint256',
        name: 'powerMin',
        type: 'uint256'
      },
      {
        internalType: 'uint256',
        name: 'powerMax',
        type: 'uint256'
      },
      {
        internalType: 'uint256',
        name: 'speedMin',
        type: 'uint256'
      },
      {
        internalType: 'uint256',
        name: 'speedMax',
        type: 'uint256'
      },
      {
        internalType: 'uint256',
        name: 'intelligenceMin',
        type: 'uint256'
      },
      {
        internalType: 'uint256',
        name: 'intelligenceMax',
        type: 'uint256'
      },
      {
        internalType: 'uint256',
        name: 'agilityMin',
        type: 'uint256'
      },
      {
        internalType: 'uint256',
        name: 'agilityMax',
        type: 'uint256'
      },
      {
        internalType: 'uint256',
        name: 'price',
        type: 'uint256'
      },
      {
        internalType: 'string',
        name: 'imageUrl',
        type: 'string'
      }
    ],
    name: 'updatePlan',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'newImplementation',
        type: 'address'
      }
    ],
    name: 'upgradeTo',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'newImplementation',
        type: 'address'
      },
      {
        internalType: 'bytes',
        name: 'data',
        type: 'bytes'
      }
    ],
    name: 'upgradeToAndCall',
    outputs: [],
    stateMutability: 'payable',
    type: 'function'
  }
];
