import { HttpClient } from '@angular/common/http';
import { LoggingService } from './log';
import { Injectable } from '@angular/core';

@Injectable()
export class TimeService {
  constructor(
    private http: HttpClient,
    private logger: LoggingService
  ) {}

  public getCurrentTime(): number {
    return (
      +(new Date().getTime() / 1000).toFixed() +
      +localStorage.getItem('timeDiffrence')!
    );
  }
}
