import { IExecution_graph } from './report.model';

export enum RunStatus {
  Running,
  Finished,
  Error
}

export interface IRunConfig {
  runTrigger: {
    timer: boolean;
    price: boolean;
    poolCondition: boolean;
  };
  cron: {
    everyHour: boolean;
  };
}

export class RunConfig implements IRunConfig {
  runTrigger = {
    timer: false,
    price: false,
    poolCondition: false
  };
  cron = {
    everyHour: false
  };
}
