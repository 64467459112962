import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { IReport } from '../models';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class RunService {
  private AllRunURL = [environment.AMATES_AI_BASEURL, 'run/all'].join('/');

  constructor(private http: HttpClient) {}

  public async getAllRun(): Promise<IReport[] | []> {
    try {
      const result = <IReport[]>await this.http.get(this.AllRunURL).toPromise();
      return result;
    } catch (err: any) {
      console.error(`Cannot fetch user reports`);
      return [];
    }
  }
}
