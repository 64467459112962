import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import {
  IAssignNFTReqMdl,
  IAssignNFTRespMdl,
  IGenerateJobRespMdl,
  IJob,
  IJobTriggerRespMdl,
  IPlan
} from '../models';
import { HttpClient } from '@angular/common/http';
import { NzNotificationService } from 'ng-zorro-antd/notification';

export const JobURL = [environment.AMATES_AI_BASEURL, 'job'].join('/');
export const TriggerJobURL = [JobURL, '{0}/paused/{1}'].join('/');
export const TestJobURL = [JobURL, '{0}/run'].join('/');
export const AssignNFTURL = [JobURL, '{0}/nftTokenId'].join('/');
export const GenerateJobURL = [JobURL, '{0}/generate'].join('/');
export const ClearAgentsURL = [JobURL, 'clear_chromadb'].join('/');
@Injectable()
export class JobService {
  constructor(
    private http: HttpClient,
    private nzNotificationService: NzNotificationService
  ) {}

  public async getAllJob(): Promise<IJob[] | []> {
    try {
      const url = [JobURL, 'all'].join('/');
      const result = <IJob[]>await this.http.get(url).toPromise();
      return result;
    } catch (err: any) {
      console.error(`Cannot fetch user jobs`);
      return [];
    }
  }

  public async triggerJob(job: IJob): Promise<IJobTriggerRespMdl | undefined> {
    try {
      const url = TriggerJobURL.replace('{0}', job.id.toString()).replace(
        '{1}',
        (!job.paused).toString()
      );
      const result = <IJobTriggerRespMdl>(
        await this.http.post(url, null).toPromise()
      );

      return result;
    } catch (err: any) {
      console.error(`Cannot fetch user jobs`);
      return undefined;
    }
  }

  public async testJob(job: IJob): Promise<IJobTriggerRespMdl | undefined> {
    try {
      const url = TestJobURL.replace('{0}', job.id.toString());
      const result = <IJobTriggerRespMdl>(
        await this.http.post(url, null).toPromise()
      );

      return result;
    } catch (err: any) {
      console.error(`Cannot fetch user jobs`);
      return undefined;
    }
  }

  public async assignNFT(
    job: IJob,
    nft: IPlan,
    assign: boolean
  ): Promise<IAssignNFTRespMdl | undefined> {
    try {
      const url = AssignNFTURL.replace('{0}', job.id.toString());
      let data: IAssignNFTReqMdl = {};

      if (assign && nft) {
        data.nftTokenId = nft.tokenId;
      }

      const result = <IAssignNFTRespMdl>(
        await this.http.post(url, data).toPromise()
      );

      return result;
    } catch (err: any) {
      console.error(`Cannot fetch user jobs`);
      return undefined;
    }
  }

  public async generateJob(
    room_id: string,
    onClocse: () => void
  ): Promise<IGenerateJobRespMdl | undefined> {
    try {
      const url = GenerateJobURL.replace('{0}', room_id);
      
      const result = <IGenerateJobRespMdl>(
        await this.http.post(url, {}).toPromise()
      );

      this.nzNotificationService
        .success('Success', 'Your Assistant Has Been Generated', {
          nzPlacement: 'bottomRight'
        })
        .onClose.subscribe(() => {
          onClocse();
        });

      return result;
    } catch (err: any) {
      console.error(`Cannot Generate Your Assistant`);
      this.nzNotificationService.error(
        'Error',
        'Cannot Generate Your Assistant',
        {
          nzPlacement: 'bottomRight'
        }
      );
      return { error: err.error };
    }
  }

  public async clearAgents(): Promise<undefined> {
    try {
      const url = ClearAgentsURL;

      await this.http.post(url, {}).toPromise();
    } catch (err: any) {
      console.error(`Cannot clear agents`);
      return undefined;
    }
  }
}
