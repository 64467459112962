"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Dexchanges = void 0;
const networks_1 = require("./utils/networks");
exports.Dexchanges = {
  CrowdswapAggregator: {
    name: 'CrowdswapAggregator',
    displayName: 'CrowdSwap',
    isCrowdSwapContractEnabled: true,
    networks: {
      [networks_1.Networks.MAINNET]: {
        contractAddress: '0x467eC2d26Bb7DE784A4584c6762B43eb69e65636',
        startBlockNumber: 14060845,
        code: 0x00
      },
      [networks_1.Networks.BSCMAIN]: {
        contractAddress: '0xd4560c06db2bAe0b06E9243896aD48e4bD14cdb2',
        startBlockNumber: 14474815,
        code: 0x00
      },
      [networks_1.Networks.BSCTEST]: {
        contractAddress: '0xacAAD297A81b2688ea7749AB9Ea83D1c43Fff579',
        code: 0x00
      },
      [networks_1.Networks.POLYGON_MAINNET]: {
        contractAddress: '0xBBc607D84eE5836C802B8b98392C8EAd8B9cDa5D',
        distributionContractAddress: '0xBeDf619c69f5C1655E58463B85A4EE67629dE409',
        stakingContractAddress: '0x3C868fe859eF46a133e032f22B443e6Efd617449',
        startBlockNumber: 23877794,
        code: 0x00
      },
      [networks_1.Networks.POLYGON_MUMBAI]: {
        contractAddress: '0x190D618e4fa573382F0F49bFF52AB8ab1d72c3c2',
        distributionContractAddress: '0x897c43897fD77866542bCFF341a6f27d7BccA45e',
        stakingContractAddress: '0x1FFa7efa9Cb519197Cb725DB672C930A85deAdA3',
        code: 0x00
      },
      [networks_1.Networks.AVALANCHE]: {
        contractAddress: '0x467eC2d26Bb7DE784A4584c6762B43eb69e65636',
        startBlockNumber: 13709734,
        code: 0x00
      },
      [networks_1.Networks.AVALANCHE_FUJI]: {
        contractAddress: '0x467eC2d26Bb7DE784A4584c6762B43eb69e65636',
        code: 0x00
      },
      [networks_1.Networks.APEX]: {
        contractAddress: '0x467eC2d26Bb7DE784A4584c6762B43eb69e65636',
        startBlockNumber: 3573542
      },
      [networks_1.Networks.ARBITRUM]: {
        contractAddress: '0x467eC2d26Bb7DE784A4584c6762B43eb69e65636',
        startBlockNumber: 69118414
      },
      [networks_1.Networks.ZKSYNC]: {
        contractAddress: '0x35c8E0D08450a0ED92124bB203c7b00E7e0ccB0B',
        startBlockNumber: 23041194
      },
      [networks_1.Networks.OPTIMISM]: {
        contractAddress: '0x467ec2d26bb7de784a4584c6762b43eb69e65636',
        startBlockNumber: 111366814
      },
      [networks_1.Networks.LINEA]: {
        contractAddress: '0x467ec2d26bb7de784a4584c6762b43eb69e65636',
        startBlockNumber: 761880
      },
      [networks_1.Networks.BASE]: {
        contractAddress: '0x467ec2d26bb7de784a4584c6762b43eb69e65636',
        startBlockNumber: 6029198
      }
    },
    liquidityUSD: '0',
    volumeUSD: '0',
    iconUrl: './assets/img/platformsIcon/crowdswap-platform-logo.png',
    observable: true,
    feeConfiguration: {
      gatheringFeeIsEnabled: true,
      feePercentage: '100000000000000000',
      eventName: 'SwapSucceedEvent'
    }
  },
  CrowdswapAggregatorV3: {
    name: 'CrowdswapAggregatorV3',
    displayName: 'CrowdSwap',
    isCrowdSwapContractEnabled: true,
    networks: {
      [networks_1.Networks.MAINNET]: {
        contractAddress: '0x5ebd2cCFeC1a7133cBE45dE4d9e0a0Ec3917524a',
        startBlockNumber: 19631806,
        code: 0x00
      },
      [networks_1.Networks.BSCMAIN]: {
        contractAddress: '0x5ebd2cCFeC1a7133cBE45dE4d9e0a0Ec3917524a',
        startBlockNumber: 37765639,
        code: 0x00
      },
      [networks_1.Networks.POLYGON_MAINNET]: {
        contractAddress: '0x5ebd2cCFeC1a7133cBE45dE4d9e0a0Ec3917524a',
        startBlockNumber: 55697578,
        code: 0x00
      },
      [networks_1.Networks.AVALANCHE]: {
        contractAddress: '0x5ebd2cCFeC1a7133cBE45dE4d9e0a0Ec3917524a',
        startBlockNumber: 44064674,
        code: 0x00
      },
      [networks_1.Networks.ARBITRUM]: {
        contractAddress: '0x5ebd2cCFeC1a7133cBE45dE4d9e0a0Ec3917524a',
        startBlockNumber: 199901742
      },
      [networks_1.Networks.ZKSYNC]: {
        contractAddress: '0x90a32C5328fB50E59083DC80F7534f88A4eE2A58',
        startBlockNumber: 31816698
      },
      [networks_1.Networks.OPTIMISM]: {
        contractAddress: '0x5ebd2cCFeC1a7133cBE45dE4d9e0a0Ec3917524a',
        startBlockNumber: 118619099
      },
      [networks_1.Networks.LINEA]: {
        contractAddress: '0x5ebd2cCFeC1a7133cBE45dE4d9e0a0Ec3917524a',
        startBlockNumber: 3625038
      },
      [networks_1.Networks.BASE]: {
        contractAddress: '0x5ebd2cCFeC1a7133cBE45dE4d9e0a0Ec3917524a',
        startBlockNumber: 13063899
      },
      [networks_1.Networks.DEFI]: {
        contractAddress: '0x42B731E5C52340829ce379f830e98CDc8CA48e08',
        startBlockNumber: 498614
      },
      [networks_1.Networks.ROOTSTOCK]: {
        contractAddress: '0x5ebd2cCFeC1a7133cBE45dE4d9e0a0Ec3917524a',
        startBlockNumber: 6573005
      }
    },
    liquidityUSD: '0',
    volumeUSD: '0',
    iconUrl: './assets/img/platformsIcon/crowdswap-platform-logo.png',
    observable: true,
    feeConfiguration: {
      gatheringFeeIsEnabled: true,
      feePercentage: '100000000000000000',
      eventName: 'SwapSucceedEvent'
    }
  },
  Crowdswap: {
    name: 'Crowdswap',
    displayName: 'CrowdSwap',
    isCrowdSwapContractEnabled: true,
    networks: {
      [networks_1.Networks.MAINNET]: [{
        contractAddress: '0xcCA6Ee4754468b557E6AED9aC64F97404418134B',
        factoryAddress: '0xBeA843A2DC516c6F38F159a6a55e80Ec40Cf2286',
        initCodeHash: '0x6c6601d756cd3a1233a520fce87aa5264df60ec143e0a1ca287315bbd14864fa',
        code: 0x13
      }],
      [networks_1.Networks.POLYGON_MAINNET]: [{
        contractAddress: '0xE33cfc23F457304D64f6091E0cdD1387b56D04D2',
        factoryAddress: '0xab7DAc1dAf712693539d770A967A9Bc7ba47470c',
        initCodeHash: '0x6c6601d756cd3a1233a520fce87aa5264df60ec143e0a1ca287315bbd14864fa',
        code: 0x13
      }],
      [networks_1.Networks.BSCMAIN]: [{
        contractAddress: '0xa6340fE7D9fF3E55D614c72356689Cd3A054Bb48',
        factoryAddress: '0x08F65111CB9b517B10e5C1E63cb2224467E7988A',
        initCodeHash: '0x6c6601d756cd3a1233a520fce87aa5264df60ec143e0a1ca287315bbd14864fa',
        code: 0x13
      }],
      [networks_1.Networks.ARBITRUM]: [{
        contractAddress: '0x84645f265BfF69dDf56e24E949127a71B37d850f',
        factoryAddress: '0x9FF74EEa1e7f0F8EE437B70d68f7CDC1A1030642',
        initCodeHash: '0x6c6601d756cd3a1233a520fce87aa5264df60ec143e0a1ca287315bbd14864fa',
        code: 0x13
      }],
      [networks_1.Networks.ZKSYNC]: [{
        contractAddress: '0x246a6BEDa1c073b7c4b34b4Ba4729ddf22097668',
        factoryAddress: '0x049D3809043d137591687170Fc323DBcDFe83283',
        initCodeHash: '0x6c6601d756cd3a1233a520fce87aa5264df60ec143e0a1ca287315bbd14864fa',
        code: 0x13
      }],
      [networks_1.Networks.AVALANCHE]: [{
        contractAddress: '0x1A71774327afEe23b50B93DA5E32b1709802A814',
        factoryAddress: '0xc64bE7301C3Fe2777C628C4CE14A0771733e04f8',
        initCodeHash: '0x6c6601d756cd3a1233a520fce87aa5264df60ec143e0a1ca287315bbd14864fa',
        code: 0x13
      }]
    },
    feePercentage: '300000000000000000',
    liquidityUSD: '0',
    volumeUSD: '0',
    iconUrl: './assets/img/platformsIcon/crowdswap-platform-logo.png',
    observable: true
  },
  CrowdswapV2: {
    name: 'CrowdswapV2',
    displayName: 'CrowdSwapV2',
    isCrowdSwapContractEnabled: true,
    networks: {
      [networks_1.Networks.MAINNET]: [{
        contractAddress: '0x9Edc7a2749708a6E1b5E598E32987f2D38FdA26e',
        factoryAddress: '0xC2dE7EE06CE28D824D5695d355A9b929341D1a4F',
        initCodeHash: '0x29412649b2a2793ff120f6b059e600892a1650e660ba9385d4e81dc2ef089f61',
        startBlockNumber: 18034282,
        code: 0x27
      }],
      [networks_1.Networks.ARBITRUM]: [{
        contractAddress: '0xc6CEcB48f22E4E94580c521050635E2A961e431C',
        factoryAddress: '0xd61B1c7974DFBC4eD60ea5625f0Ba08E7C80D99a',
        initCodeHash: '0x29412649b2a2793ff120f6b059e600892a1650e660ba9385d4e81dc2ef089f61',
        startBlockNumber: 114509042,
        code: 0x27
      }],
      [networks_1.Networks.POLYGON_MAINNET]: [{
        contractAddress: '0xd02667662e03EbC6bCD30ff89A737B18a30f7a70',
        factoryAddress: '0x14Fb5ABeA0578B37D9E1A831Bb7e77Bd3d7684a6',
        initCodeHash: '0x29412649b2a2793ff120f6b059e600892a1650e660ba9385d4e81dc2ef089f61',
        startBlockNumber: 45261628,
        code: 0x27
      }],
      [networks_1.Networks.BSCMAIN]: [{
        contractAddress: '0xF55461730B44973C83F4f7d774FeeB195110a368',
        factoryAddress: '0xC85d5e5bcca8D3c56bE624178710e5f406F1aB53',
        initCodeHash: '0x29412649b2a2793ff120f6b059e600892a1650e660ba9385d4e81dc2ef089f61',
        startBlockNumber: 30757885,
        code: 0x27
      }],
      [networks_1.Networks.AVALANCHE]: [{
        contractAddress: '0x2483f2CAd3fA8926374a346e07d26eBf49c0dAD4',
        factoryAddress: '0x887b06760Bd3557bED6C7558ca327f36E35Cc076',
        initCodeHash: '0x29412649b2a2793ff120f6b059e600892a1650e660ba9385d4e81dc2ef089f61',
        startBlockNumber: 33779028,
        code: 0x27
      }],
      [networks_1.Networks.OPTIMISM]: [{
        contractAddress: '0xE793e8D6570124EdbffEF175AA800b3b8acee62B',
        factoryAddress: '0x3feb699c9812b22A4f759f8a73980Cbec9E98f27',
        initCodeHash: '0x29412649b2a2793ff120f6b059e600892a1650e660ba9385d4e81dc2ef089f61',
        startBlockNumber: 115202051,
        code: 0x27
      }],
      [networks_1.Networks.DEFI]: [{
        contractAddress: '0x046bcf34a01e449e3abe51D4E6E84773aAd8b47a',
        factoryAddress: '0x78A27eD92C2791C8d69eD8475a5e2B7BDCDf5C40',
        initCodeHash: '0xd77b1916bbbb86f6f670c9fcadb113adc9efa06dc1c4067d970d2d5e661e5a0d',
        startBlockNumber: 518622,
        code: 0x27
      }],
      [networks_1.Networks.ROOTSTOCK]: [{
        contractAddress: '0xCC578Aa025429FA53FA91E991C065D5730BBAD81',
        factoryAddress: '0xBBc607D84eE5836C802B8b98392C8EAd8B9cDa5D',
        initCodeHash: '0xd77b1916bbbb86f6f670c9fcadb113adc9efa06dc1c4067d970d2d5e661e5a0d',
        startBlockNumber: 6572499,
        code: 0x27
      }]
    },
    feePercentage: '300000000000000000',
    liquidityUSD: '0',
    volumeUSD: '0',
    iconUrl: './assets/img/platformsIcon/crowdswap-platform-logo.png',
    observable: true
  },
  UniswapV2: {
    name: 'UniswapV2',
    displayName: 'UniswapV2',
    isCrowdSwapContractEnabled: true,
    networks: {
      [networks_1.Networks.MAINNET]: [{
        contractAddress: '0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D',
        code: 0x01
      }],
      [networks_1.Networks.GOERLI]: [{
        contractAddress: '0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D',
        code: 0x01
      }]
    },
    factoryAddress: '0x5C69bEe701ef814a2B6a3EDD4B1652CB9cc5aA6f',
    initCodeHash: '0x96e8ac4277198ff8b6f785478aa9a39f403cb768dd02cbee326c3e7da348845f',
    feePercentage: '300000000000000000',
    liquidityUSD: '0',
    volumeUSD: '0',
    iconUrl: './assets/img/platformsIcon/uniswap-platform-logo.png',
    observable: true
  },
  UniswapV3: {
    name: 'UniswapV3',
    displayName: 'UniswapV3',
    isCrowdSwapContractEnabled: true,
    networks: {
      // [Networks.MAINNET]: [
      //   {
      //     contractAddress: '0x68b3465833fb72A70ecDF485E0e4C7bD8665Fc45',
      //     code: 0x02,
      //   },
      // ],
      // [Networks.GOERLI]: [
      //   {
      //     contractAddress: '0x68b3465833fb72A70ecDF485E0e4C7bD8665Fc45',
      //     code: 0x02,
      //   },
      // ],
      // [Networks.POLYGON_MAINNET]: [
      //   {
      //     contractAddress: '0x68b3465833fb72A70ecDF485E0e4C7bD8665Fc45',
      //     code: 0x02,
      //   },
      // ],
      // [Networks.POLYGON_MUMBAI]: [
      //   {
      //     contractAddress: '0x68b3465833fb72A70ecDF485E0e4C7bD8665Fc45',
      //     code: 0x02,
      //   },
      // ],
      // [Networks.ARBITRUM]: [
      //   {
      //     contractAddress: '0x68b3465833fb72A70ecDF485E0e4C7bD8665Fc45',
      //     code: 0x02,
      //   },
      // ],
      [networks_1.Networks.OPTIMISM]: [{
        contractAddress: '0x68b3465833fb72A70ecDF485E0e4C7bD8665Fc45',
        code: 0x02
      }]
    },
    factoryAddress: '0x1F98431c8aD98523631AE4a59f267346ea31F984',
    feePercentage: '300000000000000000',
    liquidityUSD: '0',
    volumeUSD: '0',
    iconUrl: './assets/img/platformsIcon/uniswap-platform-logo.png',
    observable: true
  },
  Sushiswap: {
    name: 'Sushiswap',
    displayName: 'Sushiswap',
    isCrowdSwapContractEnabled: true,
    networks: {
      [networks_1.Networks.MAINNET]: [{
        contractAddress: '0xd9e1cE17f2641f24aE83637ab66a2cca9C378B9F',
        factoryAddress: '0xC0AEe478e3658e2610c5F7A4A2E1777cE9e4f2Ac',
        code: 0x03
      }],
      [networks_1.Networks.GOERLI]: [{
        contractAddress: '0x1b02dA8Cb0d097eB8D57A175b88c7D8b47997506',
        factoryAddress: '0xc35DADB65012eC5796536bD9864eD8773aBc74C4',
        code: 0x03
      }],
      [networks_1.Networks.BSCMAIN]: [{
        contractAddress: '0x1b02dA8Cb0d097eB8D57A175b88c7D8b47997506',
        factoryAddress: '0xc35DADB65012eC5796536bD9864eD8773aBc74C4',
        code: 0x03
      }],
      [networks_1.Networks.BSCTEST]: [{
        contractAddress: '0xacAAD297A81b2688ea7749AB9Ea83D1c43Fff579',
        factoryAddress: '0xc35DADB65012eC5796536bD9864eD8773aBc74C4',
        code: 0x03
      }],
      [networks_1.Networks.POLYGON_MAINNET]: [{
        contractAddress: '0x1b02dA8Cb0d097eB8D57A175b88c7D8b47997506',
        factoryAddress: '0xc35DADB65012eC5796536bD9864eD8773aBc74C4',
        code: 0x03
      }],
      [networks_1.Networks.POLYGON_MUMBAI]: [{
        contractAddress: '0x190D618e4fa573382F0F49bFF52AB8ab1d72c3c2',
        factoryAddress: '0xc35DADB65012eC5796536bD9864eD8773aBc74C4',
        code: 0x03
      }],
      [networks_1.Networks.AVALANCHE]: [{
        contractAddress: '0x1b02dA8Cb0d097eB8D57A175b88c7D8b47997506',
        factoryAddress: '0xc35DADB65012eC5796536bD9864eD8773aBc74C4',
        code: 0x03
      }],
      [networks_1.Networks.AVALANCHE_FUJI]: [{
        contractAddress: '0x1b02dA8Cb0d097eB8D57A175b88c7D8b47997506',
        factoryAddress: '0xd00ae08403B9bbb9124bB305C09058E32C39A48c',
        code: 0x03
      }],
      [networks_1.Networks.ARBITRUM]: [{
        contractAddress: '0x1b02dA8Cb0d097eB8D57A175b88c7D8b47997506',
        factoryAddress: '0xc35DADB65012eC5796536bD9864eD8773aBc74C4',
        code: 0x26
      }],
      [networks_1.Networks.ROOTSTOCK]: [{
        contractAddress: '0xb46e319390de313b8cc95ea5aa30c7bbfd79da94',
        factoryAddress: '0xB45e53277a7e0F1D35f2a77160e91e25507f1763',
        code: 0x26
      }]
    },
    initCodeHash: '0xe18a34eb0e04b04f7a0ac29a6e80748dca96319b42c54d679cb821dca90c6303',
    feePercentage: '300000000000000000',
    liquidityUSD: '0',
    volumeUSD: '0',
    iconUrl: './assets/img/platformsIcon/sushiswap-platform-logo.png',
    observable: true
  },
  Balancer: {
    name: 'Balancer',
    displayName: 'Balancer',
    isCrowdSwapContractEnabled: true,
    networks: {
      [networks_1.Networks.MAINNET]: [{
        contractAddress: '0x3E66B66Fd1d0b02fDa6C811Da9E0547970DB2f21',
        code: 0x04
      }]
    },
    feePercentage: '300000000000000000',
    liquidityUSD: '0',
    volumeUSD: '0',
    iconUrl: './assets/img/platformsIcon/balancerswap-platform-logo.png',
    observable: false
  },
  Bancor: {
    name: 'Bancor',
    displayName: 'Bancor',
    isCrowdSwapContractEnabled: true,
    networks: {
      [networks_1.Networks.MAINNET]: [{
        registryAddress: '0x52Ae12ABe5D8BD778BD5397F99cA900624CfADD4',
        code: 0x05
      }]
    },
    feePercentage: '200000000000000000',
    liquidityUSD: '0',
    volumeUSD: '0',
    iconUrl: './assets/img/platformsIcon/bancorswap-platform-logo.png',
    observable: false
  },
  Kyber: {
    name: 'Kyber',
    displayName: 'Kyber',
    isCrowdSwapContractEnabled: true,
    networks: {
      [networks_1.Networks.MAINNET]: [{
        contractAddress: '0x6131B5fae19EA4f9D964eAc0408E4408b66337b5',
        code: 0x06
      }],
      [networks_1.Networks.ARBITRUM]: [{
        contractAddress: '0x6131B5fae19EA4f9D964eAc0408E4408b66337b5',
        code: 0x06
      }],
      [networks_1.Networks.BSCMAIN]: [{
        contractAddress: '0x6131B5fae19EA4f9D964eAc0408E4408b66337b5',
        code: 0x06
      }],
      [networks_1.Networks.POLYGON_MAINNET]: [{
        contractAddress: '0x6131B5fae19EA4f9D964eAc0408E4408b66337b5',
        code: 0x06
      }],
      [networks_1.Networks.AVALANCHE]: [{
        contractAddress: '0x6131B5fae19EA4f9D964eAc0408E4408b66337b5',
        code: 0x06
      }],
      [networks_1.Networks.ZKSYNC]: [{
        contractAddress: '0x3F95eF3f2eAca871858dbE20A93c01daF6C2e923',
        code: 0x06
      }],
      [networks_1.Networks.OPTIMISM]: [{
        contractAddress: '0x6131B5fae19EA4f9D964eAc0408E4408b66337b5',
        code: 0x06
      }],
      [networks_1.Networks.LINEA]: [{
        contractAddress: '0x6131B5fae19EA4f9D964eAc0408E4408b66337b5',
        code: 0x06
      }],
      [networks_1.Networks.BASE]: [{
        contractAddress: '0x6131B5fae19EA4f9D964eAc0408E4408b66337b5',
        code: 0x06
      }]
    },
    feePercentage: '80000000000000000',
    liquidityUSD: '0',
    volumeUSD: '0',
    iconUrl: 'https://cdn-images-1.medium.com/max/130/1*MwDrRuiVNgzyYfscMdW27g@2x.png',
    observable: true
  },
  Pancake: {
    name: 'Pancake',
    displayName: 'Pancake',
    isCrowdSwapContractEnabled: true,
    networks: {
      [networks_1.Networks.BSCMAIN]: [{
        contractAddress: '0x10ED43C718714eb63d5aA57B78B54704E256024E',
        factoryAddress: '0xcA143Ce32Fe78f1f7019d7d551a6402fC5350c73',
        initCodeHash: '0x00fb7f630766e6a796048ea87d01acd3068e8ff67d078148a3fa3f4a84f69bd5',
        code: 0x07
      }],
      [networks_1.Networks.BSCTEST]: [{
        contractAddress: '0xD99D1c33F9fC3444f8101754aBC46c52416550D1',
        factoryAddress: '0x6725F303b657a9451d8BA641348b6761A6CC7a17',
        initCodeHash: '0xd0d4c4cd0848c93cb4fd1f498d7013ee6bfb25783ea21593d5834f5d250ece66',
        code: 0x07
      }]
    },
    feePercentage: '250000000000000000',
    liquidityUSD: '0',
    volumeUSD: '0',
    iconUrl: 'https://assets.coingecko.com/coins/images/12632/small/IMG_0440.PNG?1602654093',
    observable: true
  },
  Quickswap: {
    name: 'Quickswap',
    displayName: 'QuickswapV2',
    isCrowdSwapContractEnabled: true,
    networks: {
      [networks_1.Networks.POLYGON_MAINNET]: [{
        contractAddress: '0xa5E0829CaCEd8fFDD4De3c43696c57F7D7A678ff',
        code: 0x08
      }],
      [networks_1.Networks.POLYGON_MUMBAI]: [{
        contractAddress: '0xa5E0829CaCEd8fFDD4De3c43696c57F7D7A678ff',
        code: 0x08
      }]
    },
    factoryAddress: '0x5757371414417b8c6caad45baef941abc7d3ab32',
    initCodeHash: '0x96e8ac4277198ff8b6f785478aa9a39f403cb768dd02cbee326c3e7da348845f',
    feePercentage: '300000000000000000',
    liquidityUSD: '0',
    volumeUSD: '0',
    iconUrl: 'https://s2.coinmarketcap.com/static/img/exchanges/64x64/1293.png',
    observable: true
  },
  Apeswap: {
    name: 'Apeswap',
    displayName: 'Apeswap',
    isCrowdSwapContractEnabled: true,
    networks: {
      [networks_1.Networks.BSCMAIN]: [{
        contractAddress: '0xcF0feBd3f17CEf5b47b0cD257aCf6025c5BFf3b7',
        factoryAddress: '0x0841BD0B734E4F5853f0dD8d7Ea041c241fb0Da6',
        initCodeHash: '0xf4ccce374816856d11f00e4069e7cada164065686fbef53c6167a63ec2fd8c5b',
        code: 0x09
      }],
      [networks_1.Networks.BSCTEST]: [{
        contractAddress: '0x3380aE82e39E42Ca34EbEd69aF67fAa0683Bb5c1',
        factoryAddress: '0x152349604d49c2Af10ADeE94b918b051104a143E',
        initCodeHash: '0xf4ccce374816856d11f00e4069e7cada164065686fbef53c6167a63ec2fd8c5b',
        code: 0x09
      }],
      [networks_1.Networks.POLYGON_MAINNET]: [{
        contractAddress: '0xC0788A3aD43d79aa53B09c2EaCc313A787d1d607',
        factoryAddress: '0xCf083Be4164828f00cAE704EC15a36D711491284',
        initCodeHash: '0x511f0f358fe530cda0859ec20becf391718fdf5a329be02f4c95361f3d6a42d8',
        code: 0x09
      }]
    },
    feePercentage: '250000000000000000',
    liquidityUSD: '0',
    volumeUSD: '0',
    iconUrl: './assets/img/platformsIcon/apeswap-platform-icon.png',
    observable: true
  },
  CrowdSwapOTC: {
    name: 'CrowdSwapOTC',
    displayName: 'CrowdSwap',
    isCrowdSwapContractEnabled: false,
    networks: {
      [networks_1.Networks.POLYGON_MAINNET]: [{
        contractAddress: '0xD0CB30C449388Ea332405653c6C366ed3a91C3AF',
        acceptableInputTokens: ['USDT', 'USDC'],
        acceptableOutputTokens: ['CROWD'],
        investToken: 'USDT',
        code: 0xa
      }],
      [networks_1.Networks.BSCMAIN]: [{
        contractAddress: '0xC9aFbB53681749cC38Db6651E7801553Dfe544Cb',
        acceptableInputTokens: ['USDT', 'USDC', 'BUSD', 'DAI', 'BNB', 'CAKE', 'WBNB'],
        acceptableOutputTokens: ['CROWD'],
        investToken: 'BNB',
        code: 0xa
      }],
      [networks_1.Networks.ARBITRUM]: [{
        contractAddress: '0xeFAD2a9eda08E88356d2448dAfD4411ED58EA1A8',
        acceptableInputTokens: ['USDT', 'USDC', 'DAI', 'ETH', 'ARB', 'WETH'],
        acceptableOutputTokens: ['CROWD'],
        investToken: 'ARB',
        code: 0xa
      }],
      [networks_1.Networks.AVALANCHE]: [{
        contractAddress: '0x2E2F10c11B143b1Ff83534f959c3Bf9Cbe033e7D',
        acceptableInputTokens: ['AVAX', 'WAVAX', 'USDT.e', 'USDC.e', 'BUSD.e', 'DAI.e'],
        acceptableOutputTokens: ['CROWD'],
        investToken: 'AVAX',
        code: 0xa
      }],
      [networks_1.Networks.MAINNET]: [{
        contractAddress: '0xe2239cc6a34615b56eFE2a273798B5a833Ae0e43',
        acceptableInputTokens: ['USDT', 'USDC', 'DAI', 'WETH', 'ETH'],
        acceptableOutputTokens: ['CROWD'],
        investToken: 'ETH',
        code: 0xa
      }]
    },
    feePercentage: '300000000000000000',
    liquidityUSD: '0',
    volumeUSD: '0',
    iconUrl: './assets/img/platformsIcon/crowdswap-platform-logo.png',
    observable: true
  },
  Curve: {
    name: 'Curve',
    displayName: 'Curve v1',
    isCrowdSwapContractEnabled: true,
    networks: {
      [networks_1.Networks.MAINNET]: [{
        contractAddress: '0xfA9a30350048B2BF66865ee20363067c66f67e58',
        code: 0x28
      }, {
        contractAddress: '0xA2B47E3D5c44877cca798226B7B8118F9BFb7A56',
        code: 0x29
      }, {
        contractAddress: '0x52EA46506B9CC5Ef470C5bf89f17Dc28bB35D85C',
        code: 0x2a
      }, {
        contractAddress: '0x06364f10B501e868329afBc005b3492902d6C763',
        code: 0x2b
      }, {
        contractAddress: '0x45F783CCE6B7FF23B2ab2D70e416cdb7D6055f51',
        code: 0x2c
      }, {
        contractAddress: '0x79a8C46DeA5aDa233ABaFFD40F3A0A2B1e5A4F27',
        code: 0x2d
      }, {
        contractAddress: '0xA5407eAE9Ba41422680e2e00537571bcC53efBfD',
        code: 0x2e
      }, {
        contractAddress: '0x93054188d876f558f4a66B2EF1d97d16eDf0895B',
        code: 0x2f
      }, {
        contractAddress: '0x7fC77b5c7614E1533320Ea6DDc2Eb61fa00A9714',
        code: 0x30
      }, {
        contractAddress: '0x4CA9b3063Ec5866A4B82E437059D2C43d1be596F',
        code: 0x31
      }, {
        contractAddress: '0xbebc44782c7db0a1a60cb6fe97d0b483032ff1c7',
        code: 0x32
      }, {
        contractAddress: '0x4f062658EaAF2C1ccf8C8e36D6824CDf41167956',
        code: 0x33
      }, {
        contractAddress: '0x3eF6A01A0f81D6046290f3e2A8c5b843e738E604',
        code: 0x34
      }, {
        contractAddress: '0x3E01dD8a5E1fb3481F0F589056b428Fc308AF0Fb',
        code: 0x35
      }, {
        contractAddress: '0x0f9cb53Ebe405d49A0bbdBD291A65Ff571bC83e1',
        code: 0x36
      }, {
        contractAddress: '0xE7a24EF0C5e95Ffb0f6684b813A78F2a3AD7D171',
        code: 0x37
      }, {
        contractAddress: '0x8474DdbE98F5aA3179B3B3F5942D724aFcdec9f6',
        code: 0x38
      }, {
        contractAddress: '0xC18cC39da8b11dA8c3541C598eE022258F9744da',
        code: 0x39
      }, {
        contractAddress: '0xC25099792E9349C7DD09759744ea681C7de2cb66',
        code: 0x3a
      }, {
        contractAddress: '0x8038C01A0390a8c547446a0b2c18fc9aEFEcc10c',
        code: 0x3b
      }, {
        contractAddress: '0x7F55DDe206dbAD629C080068923b36fe9D6bDBeF',
        code: 0x3c
      }, {
        contractAddress: '0x071c661B4DeefB59E2a3DdB20Db036821eeE8F4b',
        code: 0x3d
      }, {
        contractAddress: '0xd81dA8D904b52208541Bade1bD6595D8a251F8dd',
        code: 0x3e
      }, {
        contractAddress: '0x890f4e345B1dAED0367A877a1612f86A1f86985f',
        code: 0x3f
      }, {
        contractAddress: '0x0Ce6a5fF5217e38315f87032CF90686C96627CAA',
        code: 0x40
      }, {
        contractAddress: '0xc5424b857f758e906013f3555dad202e4bdb4567',
        code: 0x41
      }, {
        contractAddress: '0xDeBF20617708857ebe4F679508E7b7863a8A8EeE',
        code: 0x42
      }, {
        contractAddress: '0xDC24316b9AE028F1497c275EB9192a3Ea0f67022',
        code: 0x43
      }, {
        contractAddress: '0xEB16Ae0052ed37f479f7fe63849198Df1765a733',
        code: 0x44
      }, {
        contractAddress: '0xA96A65c051bF88B4095Ee1f2451C2A9d43F53Ae2',
        code: 0x45
      }, {
        contractAddress: '0x42d7025938bEc20B69cBae5A77421082407f053A',
        code: 0x46
      }, {
        contractAddress: '0x2dded6Da1BF5DBdF597C45fcFaa3194e53EcfeAF',
        code: 0x47
      }, {
        contractAddress: '0xF178C0b5Bb7e7aBF4e12A4838C7b7c5bA2C623c0',
        code: 0x48
      }, {
        contractAddress: '0xecd5e75afb02efa118af914515d6521aabd189f1',
        code: 0x49
      }, {
        contractAddress: '0xd632f22692FaC7611d2AA1C0D552930D43CAEd3B',
        code: 0x4a
      }, {
        contractAddress: '0xEd279fDD11cA84bEef15AF5D39BB4d4bEE23F0cA',
        code: 0x4b
      }, {
        contractAddress: '0x4807862AA8b2bF68830e4C8dc86D0e9A998e085a',
        code: 0x4c
      }, {
        contractAddress: '0xF9440930043eb3997fc70e1339dBb11F341de7A8',
        code: 0x4d
      }, {
        contractAddress: '0x43b4FdFD4Ff969587185cDB6f0BD875c5Fc83f8c',
        code: 0x4e
      }, {
        contractAddress: '0x80466c64868E1ab14a1Ddf27A676C3fcBE638Fe5',
        code: 0x4f
      }, {
        contractAddress: '0x5a6A4D54456819380173272A5E8E9B9904BdF41B',
        code: 0x50
      }, {
        contractAddress: '0xD51a44d3FaE010294C616388b506AcdA1bfAAE46',
        code: 0x51
      }, {
        contractAddress: '0xfd5db7463a3ab53fd211b4af195c5bccc1a03890',
        code: 0x52
      }, {
        contractAddress: '0x5D0F47B32fDd343BfA74cE221808e2abE4A53827',
        code: 0x53
      }, {
        contractAddress: '0x98a7F18d4E56Cfe84E3D081B40001B3d5bD3eB8B',
        code: 0x54
      }, {
        contractAddress: '0x8301AE4fc9c624d1D396cbDAa1ed877821D7C511',
        code: 0x55
      }, {
        contractAddress: '0x618788357D0EBd8A37e763ADab3bc575D54c2C7d',
        code: 0x56
      }, {
        contractAddress: '0xB576491F1E6e5E62f1d8F26062Ee822B40B0E0d4',
        code: 0x57
      }, {
        contractAddress: '0xc5FA220347375ac4f91f9E4A4AAb362F22801504',
        code: 0x58
      }, {
        contractAddress: '0x98638FAcf9a3865cd033F36548713183f6996122',
        code: 0x59
      }, {
        contractAddress: '0x752eBeb79963cf0732E9c0fec72a49FD1DEfAEAC',
        code: 0x5a
      }],
      [networks_1.Networks.BSCMAIN]: [{
        contractAddress: '0x5A7d2F9595eA00938F3B5BA1f97a85274f20b96c',
        code: 0xb
      }],
      [networks_1.Networks.POLYGON_MAINNET]: [{
        contractAddress: '0x445FE580eF8d70FF569aB36e80c647af338db351',
        code: 0x65
      }, {
        contractAddress: '0x3FCD5De6A9fC8A99995c406c77DDa3eD7E406f81',
        code: 0x66
      }, {
        contractAddress: '0xC2d95EEF97Ec6C17551d45e77B590dc1F9117C67',
        code: 0x67
      }, {
        contractAddress: '0x3Fa8ebd5d16445b42e0b6A54678718C94eA99aBC',
        code: 0x68
      }, {
        contractAddress: '0x1d8b86e3D88cDb2d34688e87E72F388Cb541B7C8',
        code: 0x69
      }, {
        contractAddress: '0x225FB4176f0E20CDb66b4a3DF70CA3063281E855',
        code: 0x6a
      }, {
        contractAddress: '0x4DF7eF55E99a56851187822d96B4E17D98A47DeD',
        code: 0x6b
      }, {
        contractAddress: '0x5ab5C56B9db92Ba45a0B46a207286cD83C15C939',
        code: 0xb
      }],
      [networks_1.Networks.AVALANCHE]: [{
        contractAddress: '0x001E3BA199B4FF4B5B6e97aCD96daFC0E2e4156e',
        code: 0x6f
      }]
    },
    feePercentage: '40000000000000000',
    liquidityUSD: '0',
    volumeUSD: '0',
    iconUrl: '',
    observable: false
  },
  Biswap: {
    name: 'Biswap',
    displayName: 'Biswap',
    isCrowdSwapContractEnabled: true,
    networks: {
      [networks_1.Networks.BSCMAIN]: [{
        contractAddress: '0x3a6d8ca21d1cf76f653a67577fa0d27453350dd8',
        code: 0xc
      }]
    },
    factoryAddress: '0x858e3312ed3a876947ea49d572a7c42de08af7ee',
    initCodeHash: '0xfea293c909d87cd4153593f077b76bb7e94340200f4ee84211ae8e4f9bd7ffdf',
    feePercentage: '100000000000000000',
    liquidityUSD: '0',
    volumeUSD: '0',
    iconUrl: './assets/img/platformsIcon/biswap-platform-logo.png',
    observable: true
  },
  Crypto: {
    name: 'Crypto',
    displayName: 'Crypto.com',
    isCrowdSwapContractEnabled: true,
    networks: {
      [networks_1.Networks.MAINNET]: [{
        contractAddress: '0xCeB90E4C17d626BE0fACd78b79c9c87d7ca181b3',
        code: 0xd
      }]
    },
    factoryAddress: '0x9deb29c9a4c7a88a3c0257393b7f3335338d9a9d',
    initCodeHash: '0x69d637e77615df9f235f642acebbdad8963ef35c5523142078c9b8f9d0ceba7e',
    feePercentage: '300000000000000000',
    liquidityUSD: '0',
    volumeUSD: '0',
    iconUrl: '',
    observable: true
  },
  CrossChain: {
    name: 'CrossChain',
    displayName: 'CrossChain',
    isCrowdSwapContractEnabled: true,
    networks: {
      [networks_1.Networks.MAINNET]: {
        adapterContractAddress: '0xA4424FC0C4797652F4584A9c470254d97C95c462',
        wToken: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
        middleTokenAddress: '0xdac17f958d2ee523a2206206994597c13d831ec7'
      },
      [networks_1.Networks.BSCMAIN]: {
        adapterContractAddress: '0xd4560c06db2bAe0b06E9243896aD48e4bD14cdb2',
        wToken: '0x1dDcaa4Ed761428ae348BEfC6718BCb12e63bFaa',
        middleTokenAddress: '0x55d398326f99059fF775485246999027B3197955'
      },
      [networks_1.Networks.POLYGON_MAINNET]: {
        adapterContractAddress: '0xBBc607D84eE5836C802B8b98392C8EAd8B9cDa5D',
        wToken: '0x1dDcaa4Ed761428ae348BEfC6718BCb12e63bFaa',
        middleTokenAddress: '0xc2132D05D31c914a87C6611C10748AEb04B58e8F'
      },
      [networks_1.Networks.AVALANCHE]: {
        adapterContractAddress: '0x3393470f403A6bc13137A4BB44Ef8A12d2ccCcfA',
        wToken: '0x28690ec942671aC8d9Bc442B667EC338eDE6dFd3',
        middleTokenAddress: '0xc7198437980c041c805A1EDcbA50c1Ce5db95118'
      },
      [networks_1.Networks.APEX]: {
        adapterContractAddress: '0x467eC2d26Bb7DE784A4584c6762B43eb69e65636',
        wToken: '0x28690ec942671aC8d9Bc442B667EC338eDE6dFd3',
        middleTokenAddress: '0xc7198437980c041c805A1EDcbA50c1Ce5db95118'
      },
      [networks_1.Networks.OPTIMISM]: {
        adapterContractAddress: '0x2EDB21388a3d83caA8C6C1A482994e80e31992c5',
        wToken: '0x0b2C639c533813f4Aa9D7837CAf62653d097Ff85',
        middleTokenAddress: ''
      }
    },
    code: 0xe,
    feePercentage: '200000000000000000',
    feeTo: '0xdF9C09f9332669F6a5B06Df53a342a66D7ce7667',
    liquidityUSD: '0',
    volumeUSD: '0',
    iconUrl: '',
    observable: false
  },
  Dodo: {
    name: 'Dodo',
    displayName: 'Dodo',
    isCrowdSwapContractEnabled: false,
    networks: {
      [networks_1.Networks.MAINNET]: [{
        contractAddress: 'ANY_NOT_EMPTY_STRINGS',
        code: 0x0f
      }],
      [networks_1.Networks.BSCMAIN]: [{
        contractAddress: 'ANY_NOT_EMPTY_STRINGS',
        code: 0x0f
      }],
      [networks_1.Networks.AVALANCHE]: [{
        contractAddress: 'ANY_NOT_EMPTY_STRINGS',
        code: 0x0f
      }]
    },
    feePercentage: '300000000000000000',
    liquidityUSD: '0',
    volumeUSD: '0',
    iconUrl: './assets/img/platformsIcon/dodo-platform-logo.png',
    observable: false,
    //custom config
    dodoAPI: 'https://route-api.dodoex.io/dodoapi/getdodoroute'
  },
  TraderJoe: {
    name: 'TraderJoe',
    displayName: 'Trader Joe',
    isCrowdSwapContractEnabled: true,
    networks: {
      [networks_1.Networks.AVALANCHE]: [{
        contractAddress: '0x60aE616a2155Ee3d9A68541Ba4544862310933d4',
        code: 0x10
      }]
    },
    factoryAddress: '0x9Ad6C38BE94206cA50bb0d90783181662f0Cfa10',
    initCodeHash: '0x0bbca9af0511ad1a1da383135cf3a8d2ac620e549ef9f6ae3a4c33c2fed0af91',
    feePercentage: '300000000000000000',
    liquidityUSD: '0',
    volumeUSD: '0',
    iconUrl: '',
    observable: true
  },
  Babyswap: {
    name: 'Babyswap',
    displayName: 'BabySwap',
    isCrowdSwapContractEnabled: true,
    networks: {
      [networks_1.Networks.BSCMAIN]: [{
        contractAddress: '0x8317c460C22A9958c27b4B6403b98d2Ef4E2ad32',
        code: 0x11
      }]
    },
    factoryAddress: '0x86407bEa2078ea5f5EB5A52B2caA963bC1F889Da',
    initCodeHash: '0x48c8bec5512d397a5d512fbb7d83d515e7b6d91e9838730bd1aa1b16575da7f5',
    feePercentage: '300000000000000000',
    liquidityUSD: '0',
    volumeUSD: '0',
    iconUrl: '',
    observable: true
  },
  Radioshack: {
    name: 'Radioshack',
    displayName: 'Radioshack',
    isCrowdSwapContractEnabled: true,
    networks: {
      [networks_1.Networks.MAINNET]: [{
        contractAddress: '0x3e445e3280C5747a188DB8d0aB7762838A50E4ff',
        factoryAddress: '0x91fAe1bc94A9793708fbc66aDcb59087C46dEe10',
        code: 0x20
      }],
      [networks_1.Networks.BSCMAIN]: [{
        contractAddress: '0x5bc3ED94919af03279c8613cB807ab667cE5De99',
        factoryAddress: '0x98957ab49b8bc9f7ddbCfD8BcC83728085ecb238',
        code: 0x20
      }],
      [networks_1.Networks.POLYGON_MAINNET]: [{
        contractAddress: '0xaf877420786516fc6692372c209e0056169eebaf',
        factoryAddress: '0xB581D0A3b7Ea5cDc029260e989f768Ae167Ef39B',
        code: 0x20
      }],
      [networks_1.Networks.AVALANCHE]: [{
        contractAddress: '0x763D8D37669bB18064b410e17E3bB46BCF34F487',
        factoryAddress: '0xA0FbfDa09B8815Dd42dDC70E4f9fe794257CD9B6',
        code: 0x20
      }]
    },
    initCodeHash: '0x3eef69365a159891ca18b545ccaf0d6aca9b22c988b8deb7a3e4fa2fc2418596',
    feePercentage: '100000000000000000',
    liquidityUSD: '0',
    volumeUSD: '0',
    iconUrl: '',
    observable: true
  },
  Pangolin: {
    name: 'Pangolin',
    displayName: 'Pangolin',
    isCrowdSwapContractEnabled: true,
    networks: {
      [networks_1.Networks.AVALANCHE]: [{
        contractAddress: '0xE54Ca86531e17Ef3616d22Ca28b0D458b6C89106',
        code: 0x21
      }]
    },
    factoryAddress: '0xefa94DE7a4656D787667C749f7E1223D71E9FD88',
    initCodeHash: '0x40231f6b438bce0797c9ada29b718a87ea0a5cea3fe9a771abdd76bd41a3e545',
    feePercentage: '300000000000000000',
    liquidityUSD: '0',
    volumeUSD: '0',
    iconUrl: '',
    observable: true
  },
  Lydia: {
    name: 'Lydia',
    displayName: 'Lydia',
    isCrowdSwapContractEnabled: true,
    networks: {
      [networks_1.Networks.AVALANCHE]: [{
        contractAddress: '0xA52aBE4676dbfd04Df42eF7755F01A3c41f28D27',
        code: 0x23
      }]
    },
    factoryAddress: '0xe0C1bb6DF4851feEEdc3E14Bd509FEAF428f7655',
    initCodeHash: '0x47cc4f3a5e7a237c464e09c6758ac645084f198b8f64eedc923317ac4481a10c',
    feePercentage: '200000000000000000',
    liquidityUSD: '0',
    volumeUSD: '0',
    iconUrl: '',
    observable: true
  },
  ELK: {
    name: 'ELK',
    displayName: 'ELK',
    isCrowdSwapContractEnabled: true,
    networks: {
      [networks_1.Networks.AVALANCHE]: [{
        contractAddress: '0x9E4AAbd2B3E60Ee1322E94307d0776F2c8e6CFbb',
        code: 0x22
      }]
    },
    factoryAddress: '0x091d35d7F63487909C863001ddCA481c6De47091',
    initCodeHash: '0x33c4831a098654d3d20a78641a198ee6ffc1ceed49f2196b75bb244891c260e3',
    feePercentage: '300000000000000000',
    liquidityUSD: '0',
    volumeUSD: '0',
    iconUrl: '',
    observable: true
  },
  Sphynx: {
    name: 'Sphynx',
    displayName: 'Sphynx',
    isCrowdSwapContractEnabled: true,
    networks: {
      [networks_1.Networks.APEX]: {
        contractAddress: '0x159F7cF74B556335C210357470B9aF62a8Bd1367',
        factoryAddress: '0x32d72f01be56CE445547110B5c51f9D3BaeD18a8'
      }
    },
    initCodeHash: '0x6170bd56993c14c8913ba4562f8fb7a10683ad5ad7c6a0ae141d88a6ac9660bf',
    code: 0x24,
    feePercentage: '0',
    liquidityUSD: '0',
    volumeUSD: '0',
    iconUrl: '',
    observable: true
  },
  SyncSwap: {
    name: 'SyncSwap',
    displayName: 'SyncSwap',
    isCrowdSwapContractEnabled: true,
    networks: {
      [networks_1.Networks.ZKSYNC]: [{
        contractAddress: '0x2da10A1e27bF85cEdD8FFb1AbBe97e53391C0295',
        factoryAddress: '0xf2DAd89f2788a8CD54625C60b55cD3d2D0ACa7Cb',
        stableFactoryAddress: '0x5b9f21d407F35b10CbfDDca17D5D84b129356ea3',
        code: 0x25
      }]
    },
    feePercentage: '300000000000000000',
    liquidityUSD: '0',
    volumeUSD: '0',
    iconUrl: './assets/img/platformsIcon/uniswap-platform-logo.png',
    observable: false
  },
  OpenOcean: {
    name: 'OpenOcean',
    displayName: 'OpenOcean',
    isCrowdSwapContractEnabled: false,
    networks: {
      // [Networks.MAINNET]: [
      //   {
      //     contractAddress: '0x6352a56caadC4F1E25CD6c75970Fa768A3304e64',
      //     code: 0x70,
      //   },
      // ],
      // [Networks.ARBITRUM]: [
      //   {
      //     contractAddress: '0x6352a56caadC4F1E25CD6c75970Fa768A3304e64',
      //     code: 0x70,
      //   },
      // ],
      // [Networks.BSCMAIN]: [
      //   {
      //     contractAddress: '0x6352a56caadC4F1E25CD6c75970Fa768A3304e64',
      //     code: 0x70,
      //   },
      // ],
      // [Networks.POLYGON_MAINNET]: [
      //   {
      //     contractAddress: '0x6352a56caadC4F1E25CD6c75970Fa768A3304e64',
      //     code: 0x70,
      //   },
      // ],
      // [Networks.AVALANCHE]: [
      //   {
      //     contractAddress: '0x6352a56caadC4F1E25CD6c75970Fa768A3304e64',
      //     code: 0x70,
      //   },
      // ],
      // [Networks.ZKSYNC]: [
      //   {
      //     contractAddress: '0x36A1aCbbCAfca2468b85011DDD16E7Cb4d673230',
      //     code: 0x70,
      //   },
      // ],
      [networks_1.Networks.OPTIMISM]: [{
        contractAddress: '0x6352a56caadC4F1E25CD6c75970Fa768A3304e64',
        code: 0x70
      }],
      // [Networks.LINEA]: [
      //   {
      //     contractAddress: '0x6352a56caadC4F1E25CD6c75970Fa768A3304e64',
      //     code: 0x70,
      //   },
      // ],
      // [Networks.BASE]: [
      //   {
      //     contractAddress: '0x6352a56caadC4F1E25CD6c75970Fa768A3304e64',
      //     code: 0x70,
      //   },
      // ],
      [networks_1.Networks.ROOTSTOCK]: [{
        contractAddress: '0x6352a56caadC4F1E25CD6c75970Fa768A3304e64',
        code: 0x70
      }]
    },
    feePercentage: '0',
    liquidityUSD: '0',
    volumeUSD: '0',
    iconUrl: 'https://cdn-images-1.medium.com/max/130/1*MwDrRuiVNgzyYfscMdW27g@2x.png',
    observable: true
  },
  OKX: {
    name: 'OKX',
    displayName: 'OKX',
    isCrowdSwapContractEnabled: true,
    networks: {
      [networks_1.Networks.MAINNET]: [{
        contractAddress: '0xf3de3c0d654fda23dad170f0f320a92172509127',
        approveAddress: '0x40aA958dd87FC8305b97f2BA922CDdCa374bcD7f',
        code: 0x71
      }],
      [networks_1.Networks.ARBITRUM]: [{
        contractAddress: '0xf332761c673b59B21fF6dfa8adA44d78c12dEF09',
        approveAddress: '0x70cBb871E8f30Fc8Ce23609E9E0Ea87B6b222F58',
        code: 0x71
      }],
      [networks_1.Networks.BSCMAIN]: [{
        contractAddress: '0x9333C74BDd1E118634fE5664ACA7a9710b108Bab',
        approveAddress: '0x2c34A2Fb1d0b4f55de51E1d0bDEfaDDce6b7cDD6',
        code: 0x71
      }],
      [networks_1.Networks.POLYGON_MAINNET]: [{
        contractAddress: '0xA748D6573acA135aF68F2635BE60CB80278bd855',
        approveAddress: '0x3B86917369B83a6892f553609F3c2F439C184e31',
        code: 0x71
      }],
      [networks_1.Networks.AVALANCHE]: [{
        contractAddress: '0x1daC23e41Fc8ce857E86fD8C1AE5b6121C67D96d',
        approveAddress: '0x40aA958dd87FC8305b97f2BA922CDdCa374bcD7f',
        code: 0x71
      }],
      [networks_1.Networks.OPTIMISM]: [{
        contractAddress: '0xf332761c673b59B21fF6dfa8adA44d78c12dEF09',
        approveAddress: '0x68D6B739D2020067D1e2F713b999dA97E4d54812',
        code: 0x71
      }]
    },
    feePercentage: '100000000000000000',
    liquidityUSD: '0',
    volumeUSD: '0',
    iconUrl: 'https://cdn-images-1.medium.com/max/130/1*MwDrRuiVNgzyYfscMdW27g@2x.png',
    observable: true
  },
  Vanilla: {
    name: 'Vanilla',
    displayName: 'VanillaV2',
    isCrowdSwapContractEnabled: true,
    networks: {
      [networks_1.Networks.DEFI]: [{
        contractAddress: '0x3E8C92491fc73390166BA00725B8F5BD734B8fba',
        code: 0x72
      }]
    },
    factoryAddress: '0x79Ea1b897deeF37e3e42cDB66ca35DaA799E93a3',
    initCodeHash: '0xad98a069043af6c59afb11fc002e4cf7aff51f1b01e5d4d68b7741d9cca36cc1',
    feePercentage: '300000000000000000',
    liquidityUSD: '0',
    volumeUSD: '0',
    iconUrl: 'https://s2.coinmarketcap.com/static/img/exchanges/64x64/1293.png',
    observable: true
  },
  Unizen: {
    name: 'Unizen',
    displayName: 'Unizen',
    isCrowdSwapContractEnabled: true,
    networks: {
      [networks_1.Networks.MAINNET]: [{
        contractAddress: '0xd3f64BAa732061F8B3626ee44bab354f854877AC',
        code: 0x73
      }],
      [networks_1.Networks.ARBITRUM]: [{
        contractAddress: '0x1C7F7e0258c81CF41bcEa31ea4bB5191914Bf7D7',
        code: 0x73
      }],
      [networks_1.Networks.BSCMAIN]: [{
        contractAddress: '0x880E0cE34F48c0cbC68BF3E745F17175BA8c650e',
        code: 0x73
      }],
      [networks_1.Networks.POLYGON_MAINNET]: [{
        contractAddress: '0x07d0ac7671D4242858D0cebcd34ec03907685947',
        code: 0x73
      }],
      [networks_1.Networks.AVALANCHE]: [{
        contractAddress: '0x1C7F7e0258c81CF41bcEa31ea4bB5191914Bf7D7',
        code: 0x73
      }],
      [networks_1.Networks.OPTIMISM]: [{
        contractAddress: '0xad1D43efCF92133A9a0f33e5936F5ca10f2b012E',
        code: 0x73
      }]
    },
    feePercentage: '100000000000000000',
    liquidityUSD: '0',
    volumeUSD: '0',
    iconUrl: 'https://cdn-images-1.medium.com/max/130/1*MwDrRuiVNgzyYfscMdW27g@2x.png',
    observable: true
  }
};
