import { Web3Provider } from '@ethersproject/providers';
import { isAddress } from '@ethersproject/address';
import { Injectable, OnDestroy } from '@angular/core';
import {
  Conversion,
  CrowdToken,
  Networks,
  NetworksByName,
  TokensHolder,
  ERC20,
  MULTICALL,
  GasPriceHolder
} from '@crowdswap/constant';
import { ETH } from '@crowdswap/sdk';
import { BehaviorSubject, Subject } from 'rxjs';
import { BigNumber } from 'ethers';
import { NumberType, formatNumber } from '@uniswap/conedison/format.js';
import { NetworksService } from './networks.service';
import { CurrentNetwork } from '../models';
import { ERC20Service } from './erc20.service';
import MultiCall from '@indexed-finance/multicall';
import { CrowdWalletService } from './crowd-wallet.service';

declare global {
  interface Window {
    ethereum: any;
  }
}

@Injectable()
export class Web3Service {
  private provider?: any;
  private address?: string;
  private _walletNetworkChangeSubject: BehaviorSubject<number> =
    new BehaviorSubject<number>(-1);
  private _currentNetworkChangeSubject: BehaviorSubject<CurrentNetwork> =
    new BehaviorSubject<CurrentNetwork>(new CurrentNetwork(-2));
  private _wrongNetworkSubject: Subject<boolean> = new Subject<boolean>();
  private _mismatchNetworkSubject: Subject<boolean> = new Subject<boolean>();
  private _walletConnectionChangeSubject = new Subject<boolean>();
  private _pendingChangeSubject = new Subject();
  private _accountChangeSubject = new Subject<string>();
  private _assetChangeSubject: Subject<boolean> = new Subject<boolean>();
  private _isWalletConnected?: boolean;
  private _networkSpec = {
    [Networks.MAINNET]: {
      title: 'Ethereum',
      coin: 'ETH',
      coinIcon: 'assets/media/icons/Ethereum-icon.png',
      scanUrl: 'etherscan.io',
      scanName: 'Etherscan'
    },
    [Networks.ROPSTEN]: {
      title: 'Ethereum (Ropsten)',
      coin: 'ETH',
      coinIcon: 'assets/media/icons/Ethereum-icon0.png',
      scanUrl: 'ropsten.etherscan.io',
      scanName: 'Ropstenscan'
    },
    [Networks.RINKEBY]: {
      title: 'Ethereum (Rinkeby)',
      coin: 'ETH',
      coinIcon: 'assets/media/icons/Ethereum-icon0.png',
      scanUrl: 'rinkeby.etherscan.io',
      scanName: 'Rinkebyscan'
    },
    [Networks.GOERLI]: {
      title: 'Ethereum (Goerli)',
      coin: 'ETH',
      coinIcon: 'assets/media/icons/Ethereum-icon0.png',
      scanUrl: 'goerli.etherscan.io',
      scanName: 'Goerliscan'
    },
    [Networks.KOVAN]: {
      title: 'Ethereum (Kovan)',
      coin: 'ETH',
      coinIcon: 'assets/media/icons/Ethereum-icon0.png',
      scanUrl: 'kovan.etherscan.io',
      scanName: 'Kovanscan'
    },
    [Networks.BSCMAIN]: {
      title: 'BSC',
      coin: 'BNB',
      coinIcon: 'assets/media/icons/Binance-icon.png',
      scanUrl: 'bscscan.com',
      scanName: 'Bscscan'
    },
    [Networks.BSCTEST]: {
      title: 'BSC (Testnet)',
      coin: 'BNB',
      coinIcon: 'assets/media/icons/Binance-icon0.png',
      scanUrl: 'testnet.bscscan.com',
      scanName: 'Bsctestscan'
    },
    [Networks.POLYGON_MAINNET]: {
      title: 'Polygon',
      coin: 'MATIC',
      coinIcon: 'assets/media/icons/Polygon-icon.png',
      scanUrl: 'polygonscan.com',
      scanName: 'Polygonscan'
    },
    [Networks.POLYGON_MUMBAI]: {
      title: 'Matic Mumbai',
      coin: 'MATIC',
      coinIcon: 'assets/media/icons/Polygon-icon0.png',
      scanUrl: 'mumbai.polygonscan.com',
      scanName: 'Mumbaiscan'
    },
    [Networks.AVALANCHE]: {
      title: 'Avalanche',
      coin: 'AVAX',
      coinIcon: 'assets/media/icons/Avalanche-icon.svg',
      scanUrl: 'snowtrace.io',
      scanName: 'Snowtrace'
    },
    [Networks.AVALANCHE_FUJI]: {
      title: 'Fuji',
      coin: 'AVAX',
      coinIcon: 'assets/media/icons/Avalanche-icon0.png',
      scanUrl: 'testnet.snowtrace.io',
      scanName: 'Snowtrace'
    },
    [Networks.APEX]: {
      title: 'APEX',
      coin: 'OMNIA',
      coinIcon: 'assets/media/icons/Apex-icon.png',
      scanUrl: 'scan.theapexchain.org',
      scanName: 'theapexchain'
    },
    [Networks.ARBITRUM]: {
      title: 'Arbitrum',
      coin: 'ETH',
      coinIcon: 'assets/media/icons/Arbitrum-icon.svg',
      scanUrl: 'arbiscan.io',
      scanName: 'Arbiscan'
    },
    [Networks.ZKSYNC]: {
      title: 'zkSync',
      coin: 'ETH',
      coinIcon: 'assets/media/icons/zksync.svg',
      scanUrl: 'explorer.zksync.io',
      scanName: 'zkScan'
    },
    [Networks.ZKSYNCTEST]: {
      title: 'ZkSync Test',
      coin: 'ETH',
      coinIcon: 'assets/media/icons/zksync.svg',
      scanUrl: 'goerli.explorer.zksync.io',
      scanName: 'zkScan'
    },
    [Networks.OPTIMISM]: {
      title: 'Optimism',
      coin: 'ETH',
      coinIcon: 'assets/media/icons/optimism.png',
      scanUrl: 'optimistic.etherscan.io',
      scanName: 'optiscan'
    },
    [Networks.LINEA]: {
      title: 'Linea',
      coin: 'ETH',
      coinIcon: 'assets/media/icons/linea.png',
      scanUrl: 'lineascan.build',
      scanName: 'lineascan'
    },
    [Networks.BASE]: {
      title: 'Base',
      coin: 'ETH',
      coinIcon: 'assets/media/icons/base.png',
      scanUrl: 'basescan.org',
      scanName: 'basescan'
    },
    [Networks.DEFI]: {
      title: 'DeFiMetaChain',
      coin: 'DFI',
      coinIcon: 'assets/media/icons/defi.png',
      scanUrl: 'mainnet-dmc.mydefichain.com:8441',
      scanName: 'DefiScan'
    },
    [Networks.ROOTSTOCK]: {
      title: 'Rootstock',
      coin: 'RBTC',
      coinIcon: 'assets/media/icons/rootstock.png',
      scanUrl: 'rootstock.blockscout.com',
      scanName: 'RootstockScan'
    }
  };

  constructor(protected networksService: NetworksService) {
    this._currentNetworkChangeSubject.next(
      new CurrentNetwork(Networks.POLYGON_MAINNET)
    );
  }

  connectedCrowdWallet(address: string) {
    try {
      this.initProvider(address);
    } catch (e) {
      console.error(e);
    }
  }

  GetMaskedWalletAddress(): string | undefined {
    let wallet;
    if (this.isConnected()) {
      wallet = this.getWalletAddress();
      if (wallet) {
        wallet =
          wallet.substring(0, wallet.length / 3) +
          '********' +
          wallet.substring(wallet.length / 3 + 8, wallet.length);
      }
    }
    return wallet;
  }

  async clearCachedProvider() {
    this.setWalletConnected(false);
    this.provider = undefined;
  }

  isConnected() {
    return this._isWalletConnected;
  }

  getWalletChainId(): number {
    return this._walletNetworkChangeSubject.getValue();
  }

  getCurrentChainId(): number {
    return this._currentNetworkChangeSubject.getValue().chainId;
  }

  getCurrentMainChainId(): number {
    const chainId: number = this.getCurrentChainId();
    return this.getCurrentMainChain(chainId.toString());
  }

  getCurrentMainChain(chainId: string): number {
    if (chainId == '-2') {
      chainId = NetworksByName.POLYGON_MAINNET.toString();
    }

    if (chainId == '56' || chainId == '97') {
      return NetworksByName.BSCMAIN;
    } else if (chainId == '137' || chainId == '80001') {
      return NetworksByName.POLYGON_MAINNET;
    } else if (chainId == '43114' || chainId == '43113') {
      return NetworksByName.AVALANCHE;
    } else if (chainId == '42161') {
      return NetworksByName.ARBITRUM;
    } else if (chainId == '324') {
      return NetworksByName.ZKSYNC;
    } else if (chainId == '10') {
      return NetworksByName.OPTIMISM;
    } else if (chainId == '59144') {
      return NetworksByName.LINEA;
    } else if (chainId == '8453') {
      return NetworksByName.BASE;
    } else if (chainId == '1130') {
      return NetworksByName.DEFI;
    } else if (chainId == '30') {
      return NetworksByName.ROOTSTOCK;
    } else {
      return NetworksByName.MAINNET;
    }
  }

  getWalletAddress(): string | undefined {
    return this.address;
  }

  async getBalance(
    token?: CrowdToken,
    provider: Web3Provider | undefined = undefined
  ): Promise<BigNumber | undefined> {
    if (!this.address || (!provider && !this.provider) || !this.isConnected()) {
      return undefined;
    }
    const inUseProvider = provider || this.provider;
    if (!token || TokensHolder.isBaseToken(token.chainId, token.address)) {
      return inUseProvider?.getBalance(this.address);
    }
    if (inUseProvider.getTokenBalance) {
      return inUseProvider.getTokenBalance(this.address, token.address);
    } else {
      return new ERC20Service(inUseProvider!!).getBalance(
        this.address,
        token.address
      );
    }
  }

  async getBalanceByUserAddress(
    token: CrowdToken,
    userAddress: string,
    provider: Web3Provider | undefined = undefined
  ): Promise<BigNumber | undefined> {
    if (!userAddress || (!provider && !this.provider) || !this.isConnected()) {
      return undefined;
    }
    const inUseProvider = provider || this.provider;
    if (!token || TokensHolder.isBaseToken(token.chainId, token.address)) {
      return inUseProvider?.getBalance(userAddress);
    }
    if (inUseProvider.getTokenBalance) {
      return inUseProvider.getTokenBalance(this.address, token.address);
    } else {
      return new ERC20Service(inUseProvider!!).getBalance(
        userAddress,
        token.address
      );
    }
  }

  async getAllBalances(tokens: any): Promise<any> {
    let inUseProvider: Web3Provider | undefined = undefined;
    if (!this.address || !this.isConnected()) {
      return undefined;
    }

    // this.address = '0xFD4f361269dCdE0bc1CB410b54c0c30331a4FC99';
    if (!tokens || tokens.length === 0) {
      return undefined;
    }
    const chainId: number = (<CrowdToken>tokens[0]).chainId;
    inUseProvider = this.networksService
      .getNetworkProvider(chainId)
      .getProvider();

    if (!inUseProvider) {
      return undefined;
    }

    try {
      const walletAddress = this.getWalletAddress();
      if (!walletAddress) {
        return;
      }
      const chainId = tokens[0].chainId;
      const ethAddress = ETH[chainId].address.toLowerCase();
      let ethBalance;
      let tokenBalances = {};

      if (NetworksByName.ZKSYNC === chainId) {
        const noneEthTokens: any[] = [];
        for (const token of tokens) {
          if (token.address.toLowerCase() === ethAddress) {
            ethBalance = await this.getBalance(token, inUseProvider);
          } else {
            noneEthTokens.push(token);
          }
        }

        const populatedTxList: [string, string | undefined][] = [];
        const networkService = this.networksService.getNetworkProvider(chainId);
        for (const token of noneEthTokens) {
          const erc20Contract = networkService.getContract(
            token.address,
            ERC20
          );
          const populatedTx =
            await erc20Contract.populateTransaction.balanceOf(walletAddress);
          populatedTxList.push([token.address, populatedTx.data]);
        }
        const multiCallContract = networkService.getContract(
          '0x47898B2C52C957663aE9AB46922dCec150a2272c',
          MULTICALL
        );
        const result =
          await multiCallContract.callStatic.aggregate(populatedTxList);
        const returnData = result.returnData;
        for (let i = 0; i < noneEthTokens.length; i++) {
          tokenBalances[noneEthTokens[i].address.toLowerCase()] = returnData[i];
        }
      } else {
        const addressList: string[] = [];
        for (const token of tokens) {
          if (token.address.toLowerCase() === ethAddress) {
            ethBalance = await this.getBalance(token, inUseProvider);
            continue;
          }
          addressList.push(token.address.toLowerCase());
        }
        const multi = new MultiCall(inUseProvider);
        const [blockNumber, balances] = await multi.getBalances(
          addressList,
          this.getWalletAddress()!
        );
        tokenBalances = balances;
      }
      return { ...tokenBalances, [ethAddress]: ethBalance };
    } catch (e) {
      console.log(e);
    }
  }

  async getSymbol(address: string): Promise<string | undefined> {
    if (!this.provider) {
      return undefined;
    }
    return new ERC20Service(this.web3Provider).getSymbol(address);
  }

  async getGasPrice(
    provider: Web3Provider | undefined = undefined
  ): Promise<any> {
    const usedProvider = provider || this.web3Provider;
    const originalGasPrice =
      GasPriceHolder.Instance.getGasPrice(usedProvider.network.chainId) ??
      (await usedProvider.getGasPrice());
    let additionalPercentage = 0;
    switch (usedProvider.network.chainId) {
      case Networks.MAINNET:
        additionalPercentage = 18;
        break;
      case Networks.BSCMAIN:
        additionalPercentage = 0;
        break;
      case Networks.POLYGON_MAINNET:
        additionalPercentage = 40;
        break;
      case Networks.ARBITRUM:
        additionalPercentage = 0;
        break;
      case Networks.AVALANCHE:
        additionalPercentage = 5;
        break;
      case Networks.ZKSYNC:
        additionalPercentage = 10;
        break;
      case Networks.OPTIMISM:
        additionalPercentage = 10;
        break;
      case Networks.LINEA:
        additionalPercentage = 10;
        break;
      case Networks.BASE:
        additionalPercentage = 10;
        break;
      case Networks.DEFI:
        additionalPercentage = 10;
        break;
      case Networks.ROOTSTOCK:
        additionalPercentage = 10;
        break;
    }
    return originalGasPrice.mul(100 + additionalPercentage).div(100);
  }

  async getCCGasPrice(): Promise<any> {
    if (!this.provider) {
      return;
    }
    return this.provider.getGasPrice();
  }

  getNetworkName(chainId: number): string {
    return (<any>Networks)[chainId];
  }

  getScanUrl(chainId?: number) {
    chainId = chainId ?? this.getCurrentChainId();
    return `https://${this.networkSpec[chainId].scanUrl}/`;
  }

  getScanTransactionUrl(hash: string, chainId?: number) {
    chainId = chainId ?? this.getCurrentChainId();
    return `https://${this.networkSpec[chainId].scanUrl}/tx/${hash}`;
  }

  getScanAddressUrl(address: string, chainId?: number) {
    chainId = chainId ?? this.getCurrentChainId();

    if (!chainId) {
      return '';
    }

    return `https://${this.networkSpec[chainId].scanUrl}/address/${address}`;
  }

  getNetworkScanName() {
    const chainId = this.getCurrentChainId();
    return this.networkSpec[chainId].scanName;
  }

  async sendTransaction(data: any) {
    return this.provider?.getSigner().sendTransaction(data);
  }

  async signMessage(message: string) {
    return this.provider?.getSigner().signMessage(message);
  }

  async getNonce(): Promise<number | undefined> {
    return this.provider?.getSigner().getTransactionCount();
  }

  async waitForTransaction(
    hash: string,
    confirmation: number,
    provider: Web3Provider | undefined = undefined
  ) {
    return (provider || this.provider)?.waitForTransaction(hash, confirmation);
  }

  get walletNetworkChangeSubject() {
    return this._walletNetworkChangeSubject;
  }

  get wrongNetworkSubject(): Subject<boolean> {
    return this._wrongNetworkSubject;
  }

  get mismatchNetworkSubject(): Subject<boolean> {
    return this._mismatchNetworkSubject;
  }

  get currentNetworkChangeSubject(): BehaviorSubject<CurrentNetwork> {
    return this._currentNetworkChangeSubject;
  }

  get walletConnectionChangeSubject() {
    return this._walletConnectionChangeSubject;
  }

  get pendingChangeSubject() {
    return this._pendingChangeSubject;
  }

  get accountChangeSubject() {
    return this._accountChangeSubject;
  }

  get assetChangeSubject(): Subject<boolean> {
    return this._assetChangeSubject;
  }

  get networkSpec() {
    return this._networkSpec;
  }

  get web3Provider() {
    return this.getNetworkProvider(this.getWalletChainId());
  }

  setWalletConnected(status: boolean) {
    this._isWalletConnected = status;
    if (!status) {
      this.address = undefined;
    }
    this._walletConnectionChangeSubject.next(status);
  }

  async addBalanceToTokens(tokens: CrowdToken[]): Promise<CrowdToken[]> {
    const balances = await this.getAllBalances(tokens);
    if (!balances) {
      return tokens.map((token) => {
        token.balance = '';
        token.balanceToDisplay = '';
        return token;
      });
    }

    const result = tokens
      .map((token) => {
        if (!balances[token.address.toLowerCase()]) {
          return token;
        }
        token.balance = Conversion.convertStringFromDecimal(
          balances[token.address.toLowerCase()].toString(),
          token.decimals
        );

        token.balanceToDisplay = formatNumber(
          parseFloat(
            Conversion.convertStringFromDecimal(
              balances[token.address.toLowerCase()].toString(),
              token.decimals
            )
          ),
          NumberType.TokenNonTx
        );

        return token;
      })
      .sort(
        (item1: CrowdToken, item2: CrowdToken) =>
          +item2.balance - +item1.balance
      );
    return result;
  }

  isAddress(address: string) {
    return isAddress(address);
  }

  getNetworkProvider(chainId: number) {
    return this.networksService.getNetworkProvider(chainId).getProvider();
  }

  protected initProvider(userAddress: string = '') {
    const chainId = this.getCurrentChainId();

    if (!chainId) {
      return;
    }

    this.address = userAddress;
    this.setWalletConnected(true);
    this._walletNetworkChangeSubject.next(chainId);
    this._accountChangeSubject.next(userAddress);
    this.provider = this.getNetworkProvider(chainId);
  }
}
