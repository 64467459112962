import { Injectable } from '@angular/core';
import { IMiniAppData, IUserDetail, MiniAppMode } from '../models';
import { ParamMap } from '@angular/router';
import { UtilsService } from './utils.service';
import { CrowdWalletService } from './crowd-wallet.service';

@Injectable()
export class MiniAppService {
  fromMiniApp = false;
  miniAppData: IMiniAppData | undefined;

  constructor(
    private readonly utilsService: UtilsService,
    private readonly crowdWalletService: CrowdWalletService
  ) {}

  initMiniAppData(params: ParamMap) {
    this.fromMiniApp = true;

    this.miniAppData = {
      callBackUrl: params.get('callBackUrl') as string,
      walletAddress: params.get('walletAddress') as string,
      email: params.get('email') as string,
      mode: params.get('mode') as MiniAppMode
    };
  }

  redirectMiniApp() {
    const userData = this.crowdWalletService.userDetail;

    if (
      !userData ||
      (userData && (!userData.email || !userData.address))
    ) {
      return;
    }

    const params = this.utilsService.encodeQueryData({
      walletToken: userData.access_token
    });

    const url = [this.miniAppData?.callBackUrl, params].join('');

    window.location.href = url;
  }
}
