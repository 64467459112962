import { Injectable } from '@angular/core';
import { GasPriceHolder, PriceService } from '@crowdswap/constant';
import { environment } from 'src/environments/environment';
import { TokensService } from './tokens.service';
import { TimeService } from './time.service';

export enum LoadState {
  Pending = 1,
  Success = 2,
  FinishedWithError = 3
}

@Injectable()
export class InitializeService {
  public LoadState = LoadState.Pending;

  constructor(
    private readonly priceService: PriceService,
    private readonly tokensService: TokensService,
    private readonly timeService: TimeService
  ) {
    PriceService.PRICE_URL = environment.PriceUrl;
    GasPriceHolder.NetworkServiceURL = environment.NETWORK_SERVICE_BASEURL;
  }

  public async Initilize(): Promise<void> {
    try {
      await this.InitilizeInternal();
    } catch (err) {
      console.error(err);
    }
  }

  private async InitilizeInternal(): Promise<void> {
    setInterval(async () => {
      try {
        await this.priceService.CachePricesForANetwork();
      } catch {}
    }, 30000);

    setInterval(
      async () => {
        try {
          await this.tokensService.GetTokensForANetwork();
        } catch {}
      },
      30 * 60 * 1000
    );

    setInterval(async () => {
      try {
        await GasPriceHolder.Instance.CacheGasPricesForAllNetworks();
      } catch {}
    }, 45 * 1000);

    this.tokensService.GetTokensForANetwork();
    this.priceService.CachePricesForANetwork();
    GasPriceHolder.Instance.CacheGasPricesForAllNetworks();

    this.LoadState = LoadState.Success;
  }
}
