import { Injectable } from '@angular/core';

@Injectable()
export class CronService {
  constructor() {}

  detectCronPeriod(cronExpression: string) {
    const [minute, hour, dayOfMonth, month, dayOfWeek] =
      cronExpression.split(' ');

    if (
      minute === '*' &&
      hour === '*' &&
      dayOfMonth === '*' &&
      month === '*' &&
      dayOfWeek === '*'
    ) {
      return 'Every minute';
    }

    if (
      minute.match(/\d/) &&
      hour === '*' &&
      dayOfMonth === '*' &&
      month === '*' &&
      dayOfWeek === '*'
    ) {
      return `Every ${minute.match(/\d+/g)![0]} minute`;
    }

    if (
      minute.match(/\d/) &&
      hour.match(/\d/) &&
      dayOfMonth === '*' &&
      month === '*' &&
      dayOfWeek === '*'
    ) {
      const match = cronExpression.match(/^(\d{1,2})\s+(\d{1,2})/) ?? [];
      const minuteDigit = match[1].padStart(2, '0');
      const hourDigit = match[2].padStart(2, '0');

      return `Every day at ${hourDigit}:${minuteDigit}`;
    }

    if (
      minute.match(/\d/) &&
      hour.match(/\d/) &&
      dayOfMonth.match(/\d/) &&
      month === '*' &&
      dayOfWeek === '*'
    ) {
      return 'Every month';
    }

    if (
      minute.match(/\d/) &&
      hour.match(/\d/) &&
      dayOfMonth === '*' &&
      month === '*' &&
      dayOfWeek.match(/\d/)
    ) {
      return 'Every week';
    }

    if (
      minute.match(/\d/) &&
      hour.match(/\d/) &&
      dayOfMonth.match(/\d/) &&
      month.match(/\d/) &&
      dayOfWeek === '*'
    ) {
      return 'Every year';
    }

    return 'Custom period';
  }
}
