import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable()
export class PortfolioService {
  constructor(private http: HttpClient) {}

  async getReceiverInfo(
    address: string,
    chainId: number,
    walletAddress: string
  ): Promise<any> {
    try {
      let history: any = [];
      const url = [
        environment.PORTFOLIO_BASEURL,
        'api/v1/transfer/transfer-asset'
      ].join('/');

      const data = {
        receiverAddress: address,
        chainId: chainId,
        userAddress: walletAddress
      };
      history = await this.http
        .get(url, {
          params: data
        })
        .toPromise();
      return history;
    } catch (err: any) {
      console.log(`Cannot fetch user transaction history`);
    }
  }
}
